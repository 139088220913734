import {Component, ElementRef, OnInit} from '@angular/core';
import {User} from '../../users/user';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog.service';
import {LanguageService} from '../../../shared/language.service';
import {AdminService} from '../../admin.service';
import {UserService} from '../../../user/user.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Project} from '../project';
import {Language} from '../../../shared/language.model';
import {ServerGetUserProjectsResponseModel} from '../../../shared/serverresponse.model';
import {Observable} from 'rxjs';
import {$} from 'protractor';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TestOfProject} from "../../test";

// import any = jasmine.any;

@Component({
  selector: 'app-projectsdrag',
  templateUrl: './projects-drag.component.html',
  styleUrls: ['./projects-drag.component.scss']
})



export class ProjectsDragComponent implements OnInit {

   public editedProject;
   languages: Language[];
   projectTests = [];
   availableTests = [];
   successAnimations = [];
   failAnimations = [];
   private model: {};

   TestsToProjectFormGroup: FormGroup;

   constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private adminService: AdminService,
    private userService: UserService
  ) { }
  ngOnInit() {
     const projectId = this.route.snapshot.params.project_id;
     this.route.data.subscribe();
     this.adminService.fetchAnimation().subscribe(
       result => {
         if (result.success) {
           this.successAnimations = result.data.success_animations;
           this.failAnimations = result.data.fail_animations;
         }
       });

     this.editedProject = this.adminService.getProjectById(projectId);
     this.adminService.fetchProjectTestsFor(this.editedProject.project_id).subscribe(
      result => {
        if (result.success) {
          this.projectTests = result.data.tests;
          this.availableTests = result.data.availableTests;

        }
      }
     );
   }
   drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    let i;
    for (i = 0; i < this.projectTests.length; ++i) {
      this.projectTests[i].position = i;
    }
  }
  private initForm() {
    this.TestsToProjectFormGroup = new FormGroup({});
  }
  onSubmit() {

     let i;
     for (i = 0; i < this.projectTests.length; ++i) {
       if (this.editedProject.use_levels === 1) {
         // tslint:disable-next-line:max-line-length
         this.projectTests[i].success_animation = (document.getElementById('success_animation_' + this.projectTests[i].test_id) as HTMLSelectElement).value;
         // tslint:disable-next-line:max-line-length
         this.projectTests[i].fail_animation = (document.getElementById('fail_animation_' + this.projectTests[i].test_id) as HTMLSelectElement).value;
       } else {
         this.projectTests[i].success_animation = '';
         this.projectTests[i].fail_animation = '';
       }
     }

     this.adminService.updateProjectTests(this.editedProject.project_id, this.projectTests).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate (['/admin', 'projects']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
   }
   updateSelectedAnimations() {

   }
  noSubmit() {
     return false;
  }
}
