import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {Page} from '../../shared/admin.model';
import {AdminService} from '../admin.service';
import {LanguageService} from '../../shared/language.service';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-admin-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class AdminOverviewComponent implements OnInit {
  adminPages: Page[] = null;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public languageService: LanguageService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.adminPages = this.appService.getAdminPages().filter( page => {
      return page.name !== 'admin_overview';
    });

  }

}
