import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../shared/language.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user.service';
import {DialogService} from '../../shared/dialog.service';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  showPassword = false;
  loginFailed = false;

  constructor(
    public languageService: LanguageService,
    private userService: UserService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.initForm();
  }
  private initForm() {

    this.loginFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5)
      ]),
      stayLoggedIn: new FormControl('')
    });
  }

  onToggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    if (this.loginFormGroup.valid) {

    const email = this.loginFormGroup.value.email;
    const password = this.loginFormGroup.value.password;
    const stayLoggedIn = this.loginFormGroup.value.stayLoggedIn;


    this.userService.signIn(email, password, stayLoggedIn).subscribe(
      response => {
        if (response.error) {
          this.dialogService.openDialog('error', response.errors);
          this.loginFailed = true;
        } else {
          this.dialogService.openSnackBar('login_successful');
        }
      },
      err => {
        console.log(err);
        this.dialogService.openDialog('error', ['connection_failed']);
      });
    }
  }

  onForgottenPassword() {
    this.userService.sendForgottenPasswordMail(this.loginFormGroup.value.email).subscribe(
      result => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
    });
  }
}
