import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {TestService} from '../../admin/tests/test.service';
import {TestContent, TestEvaluation} from '../../shared/load-test';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../shared/dialog.service';
import {BehaviorSubject, fromEvent, interval, Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Language} from '../../shared/language.model';
import {LanguageService} from '../../shared/language.service';
import {AppService} from '../../app.service';
import {catchError} from 'rxjs/operators';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {PieComponent} from '../../pie/pie.component';
import {debug, log} from 'util';
import { shuffle } from 'd3-array';



@Component({
  selector: 'app-test-execute',
  templateUrl: './test-execute.component.html',
  styleUrls: ['./test-execute.component.scss']
})
export class TestExecuteComponent implements OnInit, OnDestroy {

  public test: Test = null;
  public isTestPhase;
  public isTestPhaseCompleted = false;
  private attempt: number;


  showState = 0;
  public newShowState = new BehaviorSubject<string>('');
  public step = new BehaviorSubject<number | null>(null);
  public loading = true;

  progressNumber = 1;
  progressbarValue = 100;
  curSec = 0;

  timestampViewEnterResult: number;
  timestampResultEntered: number;
  timestampViewEnterResultFromDate: number;
  timestampResultEnteredFromDate: number;

  public activeImages: TestContent[] = [];
  public imagesGradingPhase: TestContent[] = [];
  public imagesTestPhase: TestContent[] = [];

  public resultForm: FormGroup = new FormGroup({});

  public emotions;

  public subEvaluations: TestEvaluation[];
  public evaluation: TestEvaluation;

  public results = [];
  private subscriptions: Subscription[] = [];
  public feeling;
  public intensity;
  private sub: Subscription;

  constructor(
    public testService: TestService,
    private _sanitizer: DomSanitizer,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    public pieComponent: PieComponent
  ) {
  }

  ngOnInit() {

    this.emotions = this.appService.emotions;

    if (this.testService.activeGradingPhaseTestImages && this.testService.activeTest) {

      this.test = this.testService.activeTest;
      this.attempt = this.testService.activeAttempt;
      this.isTestPhase = this.testService.isTestPhase;

      const editedImagesGrading = this.testService.activeGradingPhaseTestImages;
      const editedImagesTestPhase = this.testService.activeTestPhaseTestImages;

      shuffle(editedImagesGrading);
      shuffle(editedImagesTestPhase);

      let i = 0;
      for (const editedImages of [editedImagesTestPhase, editedImagesGrading]) {
        if (editedImages) {
          for (const image of editedImages) {
            if (typeof image.image_neutral === 'string' && typeof image.image_emotional === 'string') {
              if (i !== 0) {
                this.results[image.image_id] = {
                  intensity: null,
                  emotion: null,
                  testPhase: i === 0,
                };
                this.resultForm.addControl(image.image_id, new FormGroup({
                  emotion: new FormControl(null),
                  intensity: new FormControl(null)
                }));
              }

              const newEntry = {
                image_id: image.image_id,
                image_emotional: this._sanitizer.bypassSecurityTrustResourceUrl(image.image_emotional),
                image_neutral: this._sanitizer.bypassSecurityTrustResourceUrl(image.image_neutral),
              };

              if (i !== 0) {
                this.imagesGradingPhase.push(newEntry);
              } else {
                this.imagesTestPhase.push(newEntry);
              }
            }
          }
        }
        i++;
      }

      // console.log(this.test.allow_testphase, this.imagesTestPhase.length > 0);
      if (this.test.allow_testphase === 1 && this.imagesTestPhase.length > 0) {
        this.activeImages = this.imagesTestPhase;
        this.isTestPhase = true;
        this.isTestPhaseCompleted = false;
      } else {
        this.activeImages = this.imagesGradingPhase;
        this.isTestPhase = false;
        this.isTestPhaseCompleted = true;
      }

      this.onTestStart();
    } else {
      this.router.navigate(['/tests', 'preview', this.route.snapshot.params.testId]);
    }
    this.loading = false;
    /* // Browser Back Button
    fromEvent(window, 'popstate').subscribe((e) => {
      console.log(e, 'backbutton');
    });
    */
    this.subscriptions.push(
      this.testService.intensity.subscribe(
        value => {
          this.intensity = value;
        }
      )
    );

    this.subscriptions.push(
      this.testService.feeling.subscribe(
        value => {
          this.feeling = value;
        }
      )
    );
    console.log(this);
  }

  onTestStart() {
    this.step.next(0);
    this.newShowState.next('showIntro');
    this.displayHandler();
  }


  private startTimer(seconds: number) {
    const time = seconds;
    const timer$ = interval(500);

    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec / 2 * 100 / seconds;
      this.curSec = sec / 2;

      if (this.curSec === seconds) {
        this.onInterruptTimer();
      }
    });
  }

  displayHandler() {
    const step = this.step.getValue();
    const imageCount = this.activeImages.length;
    const newShowState = this.newShowState.getValue();
    // console.log({state: [step, imageCount, this.isTestPhaseCompleted, this.isTestPhase]});

    if (step < imageCount) {
      if (this.showState === 0) {

        this.showState = 10;
        if (this.test.show_neutral_images === 0) {


          //    Skip View Neutral Image

          this.newShowState.next('skipNeutralImage');
          this.displayHandler();

        } else {


          //    View Neutral Image

          this.newShowState.next('viewNeutralImage');
          this.startTimer(this.test.neutral_image_time);

        }

      } else if (this.showState === 10) {

        this.showState = 15;


        if (this.test.show_neutral_images === 0) {

          this.newShowState.next('skipFixationImageBetweenImages');
          this.displayHandler();

        } else {

          this.newShowState.next('viewFixationImageBetweenImages');
          this.startTimer(this.test.between_image_time);
        }

      } else if (this.showState === 15) {


        //    View Fixation Cross

        this.newShowState.next('showEmotionalImage');
        this.showState = 20;

        this.startTimer(this.test.emotional_image_time);


      } else if (this.showState === 20) {


        //    View Enter Result

        this.showState = 30;
        this.newShowState.next('showPieChartAndEnterResult');


        this.startTimer(this.test.max_selection_time);
        // console.log('start', performance.now());
        this.timestampViewEnterResult = performance.now();
        this.timestampViewEnterResultFromDate = Math.round(+new Date() / 1000);

      } else if (this.showState === 30) {

        this.progressNumber = this.step.getValue() + 2;
        this.newShowState.next('hidePieChartAndEnterResult');
        // console.log('stop', performance.now());
        this.timestampResultEntered = performance.now();
        this.timestampResultEnteredFromDate = Math.round(+new Date() / 1000);
        //    View Select Result

        this.showState = 40;
        this.newShowState.next('submitSubResultIfTestPhase');

        const imageId = this.activeImages[step].image_id;

        //    Load SubTestResults if testPhase or
        // if not in test pahse if option give_feedback_after_each_image is set

        if (this.test.allow_testphase === 1 && this.isTestPhase) {

          this.getSubResultForTestPhaseImage(imageId, step);

        } else if (this.test.give_feedback_after_each_image === 1 && !this.isTestPhase) {

          this.getSubResultForTestPhaseImage(imageId, step);

        } else {


          //    Continue

          this.displayHandler();

        }
        /*

        //    Load SubTestResults if testPhase

        if (this.test.allow_testphase === 1 && this.isTestPhase) {

          this.getSubResultForTestPhaseImage(imageId, step);

        } else {


          //    Continue

          this.displayHandler();

        }

         */


      } else if (this.showState === 40) {


        //    View Fixation Cross
        //      And save data to object

        this.showState = 50;
        this.newShowState.next('fixationBetweenResultAndNextImage');

        this.saveSubGradingResult(step);

        if (!(step + 1 < imageCount)) {

          if (this.isTestPhase && !this.isTestPhaseCompleted && this.imagesGradingPhase.length > 0) {

            // console.log('toGrading');
            this.fromTestPhaseToGradingPhase();

          } else {
            // console.log('endGame');
            this.endGame();
          }
        } else {
          this.startTimer(this.test.between_image_time);
        }
      } else if (this.showState === 50) {


        //    Update Game Status

        this.newShowState.next('goToNextRound');
        this.showState = 0;
        this.step.next(step + 1);
        // console.log('updated_step', this.step.getValue());
        this.displayHandler();
      }
    } else {

      //    If the current images were the test phase update to actual test
      // console.log(this.isTestPhase, !this.isTestPhaseCompleted);
      if (this.isTestPhase && !this.isTestPhaseCompleted) {

        // console.log('toGrading');
        this.fromTestPhaseToGradingPhase();

      } else {

        //    End the Game
        // console.log('endGame');
        this.endGame();
      }
    }
  }

  private fromTestPhaseToGradingPhase()
  {
    // console.log({before: [this.step.getValue(), this.isTestPhaseCompleted, this.isTestPhase]});
    this.step.next(0);
    this.showState = 0;
    this.newShowState.next('showIntro');
    this.isTestPhaseCompleted = true;
    this.isTestPhase = false;
    // console.log({before: [this.step.getValue(), this.isTestPhaseCompleted, this.isTestPhase]});
    this.displayHandler();
  }

  public getSubResultForTestPhaseImage(imageId, step) {

    this.onStopTimerWithOutHandler();

    this.testService.getTestSubResult(imageId).subscribe(
      result => {

        this.dialogService.openTestTestphaseSubresultDialog(
          'your_result',
          this.testService.feeling.getValue() ?
            this.languageService.lang(this.testService.feeling.getValue()) :
            this.languageService.lang('not_selected'),
          this.testService.intensity.getValue(),
          this.languageService.lang(result.data.subResult.emotion),
          result.data.subResult.emotion_intensity
        ).afterClosed().subscribe(
          () => {
            this.displayHandler();
          }
        );
      }, () => {
        this.dialogService.openSnackBar('error_loading_sub_result');
        this.displayHandler();
      },
      // () => {
      //   this.displayHandler();
      // },
    );
  }

  private onStopTimerWithOutHandler() {
    this.progressbarValue = 100;
    this.sub.unsubscribe();
  }

  public onInterruptTimer() {
    this.progressbarValue = 100;
    this.sub.unsubscribe();
    this.displayHandler();
  }

  private endGame() {
    this.showState = 1000;
    this.testService.intensity.next(null);
    this.testService.feeling.next(null);
    if (this.isTestPhase) {
      this.router.navigate(['/tests', 'preview', this.test.test_id]);
    } else {
      const imageCount = this.activeImages.length;
      this.testService.submitResult(this.results, this.test.test_id, this.attempt, this.test.project_id, imageCount).subscribe(
        (result) => {
          if (result.success) {
            this.evaluation = result.data.testResult;
          } else {
            this.dialogService.openSnackBar('error_loading_result');
          }
        },
        () => {
          this.dialogService.openSnackBar('error_loading_result');
          this.router.navigate(['/', 'tests']);
        }
      );
    }

    this.testService.activeTestPhaseTestImages = null;
    this.testService.activeGradingPhaseTestImages = null;
    this.testService.activeAttempt = null;
    this.testService.activeTest = null;
    this.testService.isTestPhase = null;
  }

  public generatePDF() {
    this.testService.generatePDF(this.test.test_id, this.attempt).subscribe(
      (result) => {
        if (result.success) {
          const path = environment.donwloadCsvPath + result.data.success;
          window.open(path);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

  private saveSubGradingResult(step) {
    this.results[this.activeImages[step].image_id] = {
      timeToAnswer: this.timestampResultEntered - this.timestampViewEnterResult,
      timestampAnswerShown: this.timestampViewEnterResultFromDate,
      timestampAnswerSelected: this.timestampResultEnteredFromDate,
      emotion: this.testService.feeling.getValue(),
      intensity: this.testService.intensity.getValue()
    };

    this.testService.intensity.next(null);
    this.testService.feeling.next(null);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.subscriptions.forEach(
      subscription => {
        subscription.unsubscribe();
      }
    );

    this.testService.clearCurrentTest();
  }

  backToPreview() {
    this.dialogService.openDialog('confirm', 'you_are_about_to_cancel_running_test', false, true).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/', 'tests', 'preview', this.test.test_id])
          .catch( err => console.error(err));
      }
    });
  }
}
