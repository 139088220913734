import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {Client} from '../client';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {DialogService} from '../../shared/dialog.service';
import {LanguageService} from '../../shared/language.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class AdminClientsComponent implements OnInit, OnDestroy {
  clients: Client[] = null;
  displayedColumns: string[] = ['client_id', 'client_name', 'email', 'locked', 'edit'];
  dataSource = new MatTableDataSource(this.clients);

  // tslint:disable-next-line:variable-name
  _subscriptions: Subscription[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filterValue = '';

  constructor(
    private adminService: AdminService,
    private dialogService: DialogService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {

    if (this.adminService.clients == null) {
      this.adminService.fetchClients().subscribe();
    }

    this._subscriptions.push(this.adminService.onClientsChange.subscribe(
      (clients) => {
        if (clients !== null) {
          this.clients = clients;
          this.dataSource = new MatTableDataSource(this.clients);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }, () => {
        this.dialogService.openDialog('error', 'could_not_refresh_clients');
      }
    ));
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onToggleLocked(client: Client) {

  client.locked = (client.locked === '0' ? '1' : '0');

  this.adminService.editClient(client).subscribe(
      response => {
        if (response.success) {
          this.dialogService.openSnackBar(response.data);
        } else {
          this.dialogService.openDialog('error', response.errors);
        }
      },
    () => {
        this.dialogService.openDialog('error', 'internal_server_error');
    }
    );
  }
  onDeleteClient(clientId: string) {
    this.adminService.deleteClient(clientId).subscribe(
      response => {
        if (response.success) {
          this.dialogService.openSnackBar(response.data);
        } else {
          this.dialogService.openDialog('error', response.errors);
        }
      }
    );
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach(item => item.unsubscribe());
  }
}

export class ClientsComponent {
}
