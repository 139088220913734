import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../shared/language.service';
import {DialogService} from '../../shared/dialog.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  content = [
    {
      title: 'Sehr geehrte Probanden,',
      content: [
        '',

        'wir freuen uns Sie auf der Seite des FEEL-Tests willkommen zu heißen.\n' +
        'Hier haben Sie die Möglichkeit die mimische Erkennung von Basisemotionen wie Freude,\n' +
        'Trauer, Ekel, Überraschung, Angst und Ärger zu trainieren.',

        'Durch klicken auf „Verfügbare Tests“ gelangen Sie zu den Tests. Sie werden jeden Tag, je\n' +
        'nachdem ob Sie der Experimentalgruppe oder Kontrollgruppe angehören, für bestimmte Tests\n' +
        'freigeschaltet.',

        'Am ersten Tag haben wir noch eine kurze Übung vor den eigentlichen Test geschaltet, damit\n' +
        'Sie sich mit dem Ablauf vertraut machen können.\n' +
        'Sie sehen zuerst einen neutralen Gesichtsausdruck. Danach wird Ihnen das gleiche Gesicht\n' +
        'mit einem bestimmten emotionalen Ausdruck, beispielsweise Freude, gezeigt.\n' +
        'Auf dem Tortendiagramm klicken Sie bitte die Emotion an, die Sie passend für diesen\n' +
        'Gesichtsausdruck empfinden.\n' +
        'Gleichzeitig sollen Sie auch noch angeben welche Intensität Sie der Emotion zuordnen. Die\n' +
        'Intensitäten sind in 10-er-Stufen gegliedert. Ganz Innen im Tortendiagramm haben Sie 0%\n' +
        'Intensität. Der äußerste Ring entspricht 100% Intensität.',

        'Haben Sie beispielsweise einen Emotionsausdruck als sehr starke Freude identifiziert, klicken\n' +
        'Sie bitte auf dem Tortendiagramm, bei der jeweiligen Emotion, auf den äußersten Ring. Je\n' +
        'weiter Sie im Diagramm nach Innen klicken, desto geringer ist die gewählte Intensität.\n' +
        'Die Intensitäten sind zwischen 0% und 100% frei wählbar.',

        'Danach erhalten Sie ein kurzes Feedback, anhand dessen Sie sehen können ob die Emotion\n' +
        'und Intensität von Ihnen richtig erkannt wurde.',

        'Wir danke Ihnen für Ihre Teilnahme und wünschen Ihnen viel Spaß mit den Tests.',

        '',
        'Ihr FEEL-Test-Team',
      ]
    },
  ];

  constructor(
    public languageService: LanguageService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
  }
  onMail() {
  //   let link = 'mailto:me@example.com'
  //     + '?cc=myCCaddress@example.com'
  //     + '&subject=' + escape('This is my subject')
  //     + '&body=' + escape(document.getElementById('myText').value)
  //   ;

    this.dialogService.openDialog('confirm', 'open_app', false, true).afterClosed().subscribe(result => {
      if (result) {
        window.location.href = `mailto:feel-test@uni-ulm.de
          ?subject=${escape('Feeltest - ' + this.languageService.lang('welcome_mail_subject'))}`;
      }
    });

  }

  onCall() {
    this.dialogService.openDialog('confirm', 'open_app', false, true).afterClosed().subscribe(result => {
      if (result) {
        window.location.href = `tel:+123456789`;
      }
    });
  }
}
