import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {LanguageService} from '../../../shared/language.service';
import {DialogService} from '../../../shared/dialog.service';
import {Language} from '../../../shared/language.model';
import {Project} from '../project';

@Component({
  selector: 'app-projects-details',
  templateUrl: './projects-details.component.html',
  styleUrls: ['./projects-details.component.scss']
})
export class ProjectsDetailsComponent implements OnInit {
  formInitiallized = false;
  projectsDetailsFormGroup: FormGroup;
  project: Project;
  languages: Language[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.languages;
    if (this.adminService.projects === null ) {
      this.adminService.fetchProjects().subscribe(
        () => {
          this.project = this.adminService.getProjectById(this.route.snapshot.params.project_id);
          this.initForm();
        }
      );
    } else {
      this.project = this.adminService.getProjectById(this.route.snapshot.params.project_id);
      this.initForm();
    }


  }

  initForm() {
    this.projectsDetailsFormGroup = new FormGroup({
      project_title: new FormControl(
          this.project ? this.project.name : '', // Value beim Aufruf
          [
            Validators.required,
            Validators.maxLength(50)// weitere Validatoren
          ]),
      project_description: new FormControl(
          this.project ? this.project.description : '', // Value beim Aufruf
          [
            Validators.maxLength(255)// weitere Validatoren
           ]),
      use_levels: new FormControl(
         this.project ? this.project.use_levels : 0, // Value beim Aufruf
         [
           Validators.required,
           Validators.min(0), // weitere Validatoren
           Validators.max(1)
         ]),
      // created_at: new FormControl(
      //   this.project ? this.project.created_at : '', // Value beim Aufruf
      //   [
      //     Validators.maxLength(255)// weitere Validatoren
      //   ]),
      //   use_levels: new FormControl(
      //   this.project ? this.project.use_levels : '', // Value beim Aufruf
      //   [
      //     Validators.required,
      //     Validators.maxLength(255)// weitere Validatoren
      //   ])
      //  ,
      /*
        allowed_attempts: new FormControl(
          this.test ? this.test.allowed_attempts : '', // Value beim Aufruf
          [
            Validators.required// weitere Validatoren
          ])
        ,
        max_offset: new FormControl(
          this.test ? this.test.max_offset : '', // Value beim Aufruf
          [
            Validators.required// weitere Validatoren
          ])
        ,
        force_settings: new FormControl(
          this.test ? this.test.force_settings : '', // Value beim Aufruf
          [])
        ,
        show_neutral_images: new FormControl(
          this.test  ? this.test.show_neutral_images : '', // Value beim Aufruf
          []),
        neutral_image_time: new FormControl(
          this.test ? this.test.neutral_image_time : '', // Value beim Aufruf
          []),
        emotional_image_time: new FormControl(
          this.test ? this.test.emotional_image_time : '', // Value beim Aufruf
          [
            Validators.required// weitere Validatoren
          ]),
        between_image_time: new FormControl(
          this.test ? this.test.between_image_time : '', // Value beim Aufruf
          [
            Validators.required// weitere Validatoren
          ]),
        max_selection_time: new FormControl(
          this.test ? this.test.max_selection_time : '', // Value beim Aufruf
          [
            Validators.required// weitere Validatoren
          ]),
        play_sound_on_start: new FormControl(
          this.test ? this.test.play_sound_on_start : '', // Value beim Aufruf
          []),
        allow_testphase: new FormControl(
          this.test ? this.test.allow_testphase : ''),
        inactive: new FormControl(
          this.test ? this.test.inactive : '', // Value beim Aufruf
          [])

       */
      }
    );

    /*
    for (const language of this.languages) {
      if (language.short !== 'de') {
        this.projectsDetailsFormGroup.addControl(
          'title_' + language.short, new FormControl(
            this.project ? this.project['test_title_' + language.short] : null
          )
        );
        this.projectsDetailsFormGroup.addControl(
          'description_' + language.short, new FormControl(
            this.project ? this.project['test_description_' + language.short] : null
          )
        );
      }
    }
    */


    console.log(this.projectsDetailsFormGroup);
    this.formInitiallized = true;
  }

  onSubmit() {
    if (!this.projectsDetailsFormGroup.valid) {
      // return validator errors to user.
      console.log(this.projectsDetailsFormGroup);
      return;
    }
    let projectId = null;
    if (this.project) {
      projectId = this.project.project_id;
    }
    console.log(this.projectsDetailsFormGroup);
    this.adminService.saveProjectChanges(projectId, this.projectsDetailsFormGroup.value).subscribe(
      (result) => {
        if (result.success) {
          this.adminService.fetchProjects().subscribe();
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate(['/admin', 'projects']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

  returnToProjectOverview() {
    if (this.projectsDetailsFormGroup.dirty) {
      this.dialogService.openDialog(
        'confirm',
        'return_to_project_overview_without_saving',
        false,
        true).afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.router.navigate(['/admin', 'projects']).catch(err => console.log(err));
        }
      });
    } else {
      this.router.navigate(['/admin', 'projects']).catch(err => console.log(err));
    }
  }


  autoRenew = new FormControl();
  onChange() {
    console.log(this.autoRenew.value);
  }
}
