import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "../../dialog/dialog.component";
import {LanguageService} from "../../language.service";
import {DialogData} from "../select-time/select-time.component";

export interface DialogData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-custom-html-dialog',
  templateUrl: './custom-html-dialog.component.html',
  styleUrls: ['./custom-html-dialog.component.scss']
})
export class CustomHtmlDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public languageService: LanguageService
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

}
