import {Component, Injectable, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Chart } from 'node_modules/chart.js';
import {AppService} from '../app.service';
import {LanguageService} from '../shared/language.service';
import {TestService} from '../admin/tests/test.service';

@Injectable({providedIn: 'root' }
  )
@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
// https://www.chartjs.org/
export class PieComponent implements AfterViewInit {
  @ViewChild('chartRef', {static: false}) chartRef: any;
 feelings: any = [];
  ds: any = [];
  public emotions;
  public myChart;

  public ergebnis;
  public feeling;
  public colors = { anger: '#2f8ef5',  disgust: '#962ff5' , fear: '#00ff00', grief: '#ffff00', joy : '#ff9800', surprise: '#ff0000'};

  constructor(
    private appService: AppService,
    public languageService: LanguageService,
    public testService: TestService) {}


  calculateDistance(mouseX, mouseY) {
    //console.log(this.chartRef);
    const width = +this.chartRef.nativeElement.offsetWidth;
    const height = +this.chartRef.nativeElement.offsetHeight;
    //console.log("w=" + width + " h=" + height);

    return Math.sqrt(
        Math.pow(mouseX - width / 2, 2) +
        Math.pow(mouseY - height / 2, 2));

    /*
    return Math.floor(
      Math.sqrt(
        Math.pow(mouseX - ((width / 2)), 2) +
        Math.pow(mouseY - ((height / 2)), 2)
      ) + height
    ) - Math.round(width / 2);

     */
  }
  getIntensitity(x, y) {
// this.myChart.outerRadius
    // x
    // y
    // this.myChart.canvas.clientWidth
    // this.myChart.canvas.clientHeight

  }
  myClickHandler(event) {


    // Emotion welche geklickt wurde (pieslice)
      // let yTop = ;
      let yTop          = this.myChart.chartArea.top;
      let yBottom       = this.myChart.chartArea.bottom;
      let newY          = 0;
      let activePoints  = this.myChart.getElementAtEvent(event);
      //console.log(this.myChart);
      if(activePoints.length > 0)// check if activePointsArray is empty
      {
        let chartData = activePoints[0]['_chart'].config.data;
        let idx       = activePoints[0]['_index'];
        let emotion   = chartData.labels[idx];
        // console.log('Emotion: ' + emotion);

        let x = event.offsetX;
        let y = event.offsetY;

        let returnvalue = this.calculateDistance(x,y);
        let hundertprozent = this.myChart.outerRadius;
        let ergebnis = 100.0 * returnvalue/ hundertprozent;


        this.ergebnis = Math.ceil(ergebnis);
        //console.log("x=" + x +" y=" + y + " d=" + returnvalue + " r=" + hundertprozent + " p=" + ergebnis + " e=" + this.ergebnis);
        this.feeling = emotion;
        this.testService.feeling.next(this.feeling);
        this.testService.intensity.next(this.ergebnis);
      }
  }

  ngAfterViewInit() {

    // Emotionen laden
    this.emotions = this.appService.emotions;
    // Namen der Emotionen in feelings pushen
    for (let i = 0; i < this.emotions.length; i++) {
      this.feelings.push(this.emotions[i].name);
    }

    // dynamisch die anteile der slices ausrechnen
    const percent = 100 / this.feelings.length;
    const dataArray = [];
    for (let j = 0; j < this.feelings.length; j++)  {
      dataArray.push(percent);
    }

    //translate for tooltip
    const translated = [];
    for (let k = 0; k < this.feelings.length; k++) {
      translated.push(this.languageService.lang(this.feelings[k]));
    }
    //console.log('trans');
    //console.log(translated);
    //for (let i = 0; i < 3; i++) {
    /*
    let colors = [
      [ '#2f8ef5', '#962ff5', '#006600', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#007700', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#008800', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#009900', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00aa00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00bb00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00cc00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00dd00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00ee00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#2f8ef5', '#962ff5', '#00ff00', '#ffff00' , '#ff9800', '#FF0000' ]
    ];
     */
    // create with http://www.herethere.net/~samson/php/color_gradient/
    const colorsGradient = [
      [ '#2f8ef5', '#962ff5', '#00ff00', '#ffff00' , '#ff9800', '#FF0000' ],
      [ '#4399F6', '#A043F6', '#19FF19', '#ffff19' , '#FFA219', '#FF1919' ],
      [ '#58A4F7', '#AB58F7', '#33FF33', '#ffff33' , '#FFAC33', '#FF3333' ],
      [ '#6DAFF8', '#B56DF8', '#4CFF4C', '#ffff4C' , '#FFB64C', '#FF4C4C' ],
      [ '#82BBF9', '#C082F9', '#66FF66', '#ffff66' , '#FFC166', '#FF6666' ],
      [ '#97C6FA', '#CA97FA', '#7FFF7F', '#ffff7F' , '#FFCB7F', '#FF7F7F' ],
      [ '#ABD1FB', '#D5ABFB', '#99FF99', '#ffff99' , '#FFD599', '#FF9999' ],
      [ '#C0DDFC', '#DFC0FC', '#B2FFB2', '#ffffB2' , '#FFE0B2', '#FFB2B2' ],
      [ '#D5E8FD', '#EAD5FD', '#CCFFCC', '#ffffCC' , '#FFEACC', '#FFCCCC' ],
      [ '#EAF3FE', '#F4EAFE', '#E5FFE5', '#ffffE5' , '#FFF4E5', '#FFE5E5' ]
    ];
    for (let i = 0; i < 10; i++) {
      this.ds.push(
        {
          label: 'test',
          translated: translated,
          data: [16, 16, 16, 16, 16, 16], // can be replaced with dataArray if needed

          backgroundColor: colorsGradient[i],
          hoverBackgroundColor: [ '#95bfed', '#c79eed', '#a4fca4', '#ffffaf', '#fcd59f', '#ff9696']
        }
      );
    }
    this.generateChart();
  }

generateChart() {
  this.myChart = new Chart( 'myChart', {
    type: 'pie',
    data: {
      labels: this.feelings,
      datasets: this.ds
    },
    options: {
      responsive: false,
      legend: {
        onClick: null,
        display: false
      },
      scales: {
      }, tooltips: {
        callbacks: {
          title: function(tooltipItem, data) {
           return data['datasets'][0]['translated'][tooltipItem[0]['index']];
          },
          label: function(tooltipItem, data) {
            return '';
          },
          afterLabel: function(tooltipItem, data) {
            return '';
          }
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        displayColors: false,
        enabled: true
      }
    }
  });
}
}
