import {Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {AdminService} from '../admin.service';
import {DialogService} from '../../shared/dialog.service';
import {LanguageService} from '../../shared/language.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Client} from '../client';
import {MatTableDataSource} from '@angular/material/table';
import {User} from './user';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {Subscription, timer} from 'rxjs';
import {MatMenuTrigger} from '@angular/material/menu';
import {Router} from '@angular/router';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit, OnDestroy {

  contextMenuPosition = { x: '0px', y: '0px' };
  users: User[] | null = null;
  activeUser: User | null = null;
  filterValue = '';
  @ViewChildren(MatMenuTrigger) triggerRef;
  @ViewChild('userOptions', {static: false}) menuRef;
  displayedColumns: string[] = ['username', 'email', 'lang', 'gender', 'birthdate', 'created_at', 'activated', 'locked', 'edit'];
  dataSource = new MatTableDataSource(this.users);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    private clickedUser: User | null = null;
    now = Math.floor(new Date().getTime() / 1000);
    autoUpdaterSubscription: Subscription;
    onUserChangeSub: Subscription;

  private router: any;
  constructor(
    private adminService: AdminService,
    private dialogService: DialogService,
    public languageService: LanguageService

  ) { }

  ngOnInit() {
    let firstTimer = 30000;

    if (this.adminService.users == null) {
      firstTimer = 0;
    }
    this.autoUpdaterSubscription = timer(firstTimer, 30000).subscribe(
      () => {
        this.adminService.fetchUsers().subscribe();
      }
    );
    this.onUserChangeSub = this.adminService.onUsersChange.subscribe(
      (users) => {
        if (users !== null) {
          this.users = users;
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.applyFilter();
        }
      }
    );
  }
/*  onUserProject() {
    this.router. navigate ([
      '/admin',
      'users',
      'user-project'
    ]);
  }*/
  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onDeleteUser(user: User) {
    this.dialogService.openDialog('delete_user', ['delete_user_are_you_sure'], false, true).afterClosed().subscribe(
      result => {
        if (result) {
          this.adminService.deleteUser(user).subscribe(
            (callbackResult) => {
              if (callbackResult.success) {
                this.dialogService.openSnackBar(callbackResult.data[0]);
              } else {
                this.dialogService.openDialog('error', callbackResult.errors);
              }
            }
          );
        }
      });
    }

  onToggleLocked(user: User) {
    this.dialogService.openDialog('confirm',
      this.languageService.lang('your_about_to_lock')
      + ' '
      + user.email
      + ' '
      + this.languageService.lang('your_about_to_lock_2')
      + '. '
      + this.languageService.lang('are_you_sure'),
      true, true).afterClosed().subscribe(
      val => {
        if (val) {
          const editedUser = {...user};

          editedUser.locked = editedUser.locked === 0 ? 1 : 0;

          this.adminService.editUser(editedUser).subscribe(
            result => {
              if (result.success) {
                this.dialogService.openSnackBar(result.data['0']);
              } else {
                this.dialogService.openDialog('error', result.errors);
              }
            },
            () => {
              this.dialogService.openDialog('error', 'error_when_saving');
            }
          );
        } else {
          this.dialogService.openSnackBar('lock_user_canceled');
        }
      }
    );
  }

  onActivateUser(user: User) {
    const editedUser = {...user};

    editedUser.activated = 1;

    this.adminService.editUser(editedUser).subscribe(
      result => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data['0']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

  onResetLoginAttempts() {
    const user = this.activeUser;
    this.adminService.resetUserLoginAttempts(user).subscribe(
      result => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

  onResetPassword() {
    const user = this.activeUser;
    this.dialogService.openDialog(
      'confirm',
      [this.languageService.lang('are_you_sure_to_reset_password'), user.email],
      true,
      true).
    afterClosed()
      .subscribe(
      result => {
        if (result) {
          this.adminService.resetUserPassword(user).subscribe(
            (result) => {
              if (result.errors.length === 0) {
                this.dialogService.openSnackBar(result.data[0]);
              } else {
                this.dialogService.openDialog('error', result.errors);
              }
            }
          );
        }
      }
    );
  }

  onLockUserAt() {
    const user = this.activeUser;
    this.dialogService.openSelectTime('select_date', 'when_should_the_user_be_deleted', true, new Date()).afterClosed().subscribe(
      result => {
        if (result !== null) {
          this.adminService.lockUserAt(user, moment(result._selected.getTime() / 1000).toDate().getTime()).subscribe(
            (result) => {
              if (result.success) {
                  this.dialogService.openSnackBar(result.data[0]);
              } else {
                  this.dialogService.openDialog('error', result.errors);
              }
            });
        }
    });
  }

  onRemoveLockUserAt() {
    const user = this.activeUser;
    this.adminService.removeLockUserAt(user).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
        }
      }
    );
  }
  onClickQuickActions(user: User) {
    this.clickedUser = user;
    this.activeUser = user;
  }
  public onFetchUsers() {
    this.adminService.fetchUsers().subscribe(
      () => {
        this.dialogService.openSnackBar('users_refreshed');
      }
    );
  }

  ngOnDestroy() {
    this.autoUpdaterSubscription.unsubscribe();
    this.onUserChangeSub.unsubscribe();
  }

  onToggleMenu($event: MouseEvent, row: User) {
    $event.preventDefault();
    this.contextMenuPosition.x = $event.clientX + 'px';
    this.contextMenuPosition.y = $event.clientY + 'px';
    this.activeUser = row;
    this.triggerRef.last.openMenu();
  }

  onCloseUserContext() {
    this.activeUser = null;
  }
}

