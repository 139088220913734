import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {LanguageService} from '../language.service';

export interface DialogData {
  useRaw: boolean;
  title: string;
  content: []|string;
  okCancel: boolean;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  isArray: boolean = null;
  result = this.dialogRef.afterClosed();

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public languageService: LanguageService
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // console.log(this.data);
    this.isArray = (typeof this.data.content) === 'object';
  }

}
