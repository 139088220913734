import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {DialogService} from '../../shared/dialog.service';
import {FormControl} from '@angular/forms';
import {LanguageService} from '../../shared/language.service';
import {Client} from '../client';
import { DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss']
})
export class TestsComponent implements OnInit {
  tests: Test[] = null;
  displayedColumns: string[] = ['test_id', 'force_settings', 'give_feedback_after_each_image',
    'precision_offset', 'emotional_image_time', 'between_image_time', 'max_selection_time', 'play_sound_on_start', 'inactive', 'actions'];
  dataSource = new MatTableDataSource(this.tests);

  expandedTest: string | null;
  fileUrl;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private router: Router;

  constructor(
    private adminService: AdminService,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.adminService.tests == null) {
      this.adminService.fetchTests().subscribe();
    }
    this.adminService.onTestsChange.subscribe(
      (tests) => {
        if (tests !== null) {
          this.tests = tests;
          this.dataSource = new MatTableDataSource(this.tests);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    );
  }

  onDeleteTest(testId: string) {
    this.adminService.deleteTest(testId).subscribe(
      response => {

        if (response.success) {
          this.dialogService.openSnackBar(response. data);
        } else {
          this.dialogService.openDialog('error', response.errors);
        }
      }
    );
  }

  onExpandedTestChange(expandedTest: Test) {
    this.expandedTest = expandedTest.test_id;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onToggleInactive(test: Test) {
  }
  exportCSV(test: Test) {
    this.adminService.generateCSV(test).subscribe(
      (result) => {
        if (result.success)
        {// const data = result.data['success'];
          // const blob = new Blob([data], { type: 'text/csv' });
          // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.cre  ateObjectURL(blob));
          const path = environment.donwloadCsvPath + result.data['success'];
          window.open(path);
          this.dialogService.openSnackBar('success', result.data['0']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }
}

