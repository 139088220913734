import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../user.service';
import {DialogService} from '../../shared/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean | UrlTree>(
      subscriber => {
        this.userService.afterLogin.subscribe(
          afterLogin => {
            if (afterLogin) {

              if (this.userService.validLogin()) {
                // console.log('ACCEPTED');
                subscriber.next(true);
              } else {
                this.dialogService.openSnackBar('must_be_logged_in');
                subscriber.next(this.router.createUrlTree(['']));
              }
            }
          }
        );
      }
    );
  }
}
