import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import {
  _MatMenuDirectivesModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialog,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule, MatIconRegistry,
  MatInputModule,
  MatMenuModule, MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule, MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { AccountComponent } from './user/account/account.component';
import { HttpClientModule} from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DialogComponent } from './shared/dialog/dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import { AdminOverviewComponent } from './admin/overview/overview.component';
import { AdminClientsComponent } from './admin/clients/clients.component';
import { ClientEditComponent } from './admin/clients/edit/edit.component';
import { NewClientComponent } from './admin/clients/new/new.component';
import { ActivateComponent } from './shared/activate/activate.component';
import { UsersComponent } from './admin/users/users.component';




import { UserEditComponent } from './admin/users/edit/edit.component';
import {OldLoginComponent} from './login/login.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SlideNavComponent } from './slide-nav/slide-nav.component';
import { TestOverviewComponent } from './test-overview/test-overview.component';
import { SelectTimeComponent } from './shared/dialogs/select-time/select-time.component';
import { EditPermissionsComponent } from './admin/users/edit-permissions/edit-permissions.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import { ImprintComponent } from './shared/footer/imprint/imprint.component';
import { PrivacyPolicyComponent } from './shared/footer/privacy-policy/privacy-policy.component';
import { TosComponent } from './shared/footer/tos/tos.component';
import {TestsComponent} from './admin/tests/tests.component';
import {ImagesComponent} from './admin/tests/images/images.component';
import { NewUserComponent } from './admin/users/new-user/new-user.component';
import { TestPreviewComponent } from './test-overview/test-preview/test-preview.component';
import { TestExecuteComponent } from './test-overview/test-execute/test-execute.component';
import {SafeHtml} from './shared/safe-html.pipe';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import { CustomHtmlDialogComponent } from './shared/dialogs/custom-html-dialog/custom-html-dialog.component';
import { TestTestphaseResultsComponent } from './shared/dialogs/test-testphase-results/test-testphase-results.component';
import {TestsDetailsComponent} from './admin/tests/tests-details/tests-details.component';
import { PieComponent } from './pie/pie.component';
import { ViewUserResultsComponent } from './admin/users/view-user-results/view-user-results.component';
import { ProgressCircularComponent } from './shared/progress-circular/progress-circular.component';
import { WelcomeComponent } from './home/welcome/welcome.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { ProjectsDetailsComponent } from './admin/projects/projects-details/projects-details.component';
import { UserProjectComponent } from './admin/users/user-project/user-project.component';
import { ProjectsDragComponent } from './admin/projects/projects-drag/projects-drag.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    AccountComponent,
    HomeComponent,
    DialogComponent,
    AdminOverviewComponent,
    AdminClientsComponent,
    ClientEditComponent,
    NewClientComponent,
    ActivateComponent,
    UsersComponent,
    UserEditComponent,
    OldLoginComponent,
    SlideNavComponent,
    TestOverviewComponent,
    TestsComponent,
    ImagesComponent,
    TestOverviewComponent,
    SelectTimeComponent,
    EditPermissionsComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    TosComponent,
    TestsComponent,
    NewUserComponent,
    TestPreviewComponent,
    TestExecuteComponent,
    SafeHtml,
    CustomHtmlDialogComponent,
    TestTestphaseResultsComponent,
    TestsDetailsComponent,
    PieComponent,
    ViewUserResultsComponent,
    ProgressCircularComponent,
    WelcomeComponent,
    ProjectsComponent,
    ProjectsDetailsComponent,
    UserProjectComponent,
    ProjectsDragComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    DragDropModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSliderModule,
    FormsModule
  ],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent, SelectTimeComponent, CustomHtmlDialogComponent, TestTestphaseResultsComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/mdi.svg'));
  }
}

