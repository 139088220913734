import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../user/user.service';
import {environment} from '../../../environments/environment';
import {catchError, findIndex, tap} from 'rxjs/operators';
import {
  UserTestOverviewResponseModel,
  GeneratePDFResponse,
  ServerGetUserProjectResponseModel, AssignUserProjectResponseModel
} from '../../shared/serverresponse.model';
import {LoadTest, SubmitSubResultForTestPhaseTestResult, SubmitTestResult, TestContent} from '../../shared/load-test';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class TestService {
  onTestsChange = new BehaviorSubject<Test[]>(null);
  gradingAndTestPhaseResult: { testPhase: TestContent[]; gradingPhase: TestContent[] };
  activeGradingPhaseTestImages: TestContent[];
  activeTestPhaseTestImages: TestContent[];
  activeTest: Test;
  isTestPhase: boolean;
  activeAttempt: number;
  feeling = new BehaviorSubject<any>(null);
  intensity = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  newImage(value: any) {

  }

  fetchTestOverview() {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'GETTESTSOVERVIEW');

    return this.httpClient.post<UserTestOverviewResponseModel>(environment.ApiLocation, body)
      .pipe(tap(
        result => {
          this.onTestsChange.next(result.data.tests);
        }
      ));
  }

  removeTestFromOverview(testId: string) {
    const tests = this.onTestsChange.getValue().filter(test => {
      return test.test_id !== testId;
    });
    this.onTestsChange.next(tests);
  }

  loadTest(testId: string, isTestPhase: boolean, testsettings: any) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('testId', testId);
    const json = JSON.stringify({testId, testsettings: {testsettings}});
    const objJsonB64 = btoa(unescape(encodeURIComponent(json)));
    body.append('testsettings', objJsonB64);
    body.append('isTestPhase', isTestPhase ? '1' : '0');

    body.append('func', 'LOADTEST');

    return this.httpClient.post<LoadTest>(environment.ApiLocation, body);

  }

  getTestById(searchTestId: string): Test | null {
    const tests = this.onTestsChange.getValue();

    const searchedTestIndex = tests.findIndex( test => {
      return test.test_id === searchTestId;
    });
    if (searchedTestIndex === -1) {
      return null;
    }
    return {...tests[searchedTestIndex]};
  }

  submitResult(testResult, testId: string, testAttempt: number, project_id: string|null, imageCount: number) {
    const body = new FormData();


    //  Encode Results for request

    const json = JSON.stringify({testId, attempt: testAttempt, projectId: project_id, testCount: imageCount, testResult: {...testResult}});
    const objJsonB64 = btoa(unescape(encodeURIComponent(json)));

    body.append('token', this.userService.user.token);
    body.append('testResults', objJsonB64);
    body.append('func', 'SUBMITTESTRESULT');

    return this.httpClient.post<SubmitTestResult>(environment.ApiLocation, body)
      .pipe(tap(
        (result) => {
        if (!result.data.canRetry) {
          this.removeTestFromOverview(result.data.testId);
        }
      }));
  }

  getTestSubResult(imageId: string) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('image_id', imageId);
    body.append('func', 'GETSUBRESULTFORTESTPHASE');

    return this.httpClient
      .post<SubmitSubResultForTestPhaseTestResult>(environment.ApiLocation, body);
  }

  generatePDF(testId: string, testAttempt: any) {
const body = new FormData();

body.append('token', this.userService.user.token);
body.append('test_id', testId);
body.append('testAttempt', testAttempt);
body.append('func', 'EXPORTPDF');

return this.httpClient
  .post<GeneratePDFResponse>(environment.ApiLocation, body);
  }

  clearCurrentTest() {
    this.activeTest = null;
    this.activeAttempt = null;
    this.activeGradingPhaseTestImages = null;
    this.activeTestPhaseTestImages = null;
  }
}
