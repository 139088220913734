import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../shared/language.service';
import {MatTableDataSource} from '@angular/material/table';
import {HttpClient} from '@angular/common/http';
import {AdminService} from '../../admin.service';
import {ActivatedRoute} from '@angular/router';
import {History} from "../../../shared/serverresponse.model";
@Component({
  selector: 'app-view-user-results',
  templateUrl: './view-user-results.component.html',
  styleUrls: ['./view-user-results.component.scss']
})

export class ViewUserResultsComponent implements OnInit {
  userHistory: History[] = null;
  dataSource = new MatTableDataSource(this.userHistory);
  displayedColumns: string[] = ['run_id', 'attempt', 'passed', 'finished', 'avgDifference', 'avgInputPassed', 'avgTimeToAnswer'];

  constructor(
    public languageService: LanguageService,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.adminService.fetchUserTestHistory(this.route.snapshot.params.user_id).subscribe(
      result => {
        this.userHistory = result.data.history;
        this.dataSource = new MatTableDataSource(this.userHistory);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
