import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../shared/language.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user/user.service';
import {DialogService} from '../shared/dialog.service';

@Component({
  selector: 'app-login-old',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class OldLoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  showPassword = false;
  constructor(
    public languageService: LanguageService,
    private userService: UserService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.initForm();
  }
  private initForm() {

    this.loginFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5)
      ]),
      stayLoggedIn: new FormControl('')
    });
  }

  onToggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    if (this.loginFormGroup.valid) {

    const email = this.loginFormGroup.value.email;
    const password = this.loginFormGroup.value.password;
    const stayLoggedIn = this.loginFormGroup.value.stayLoggedIn;

    this.userService.signIn(email, password, stayLoggedIn).subscribe(
      response => {
        if (response.error) {
          this.dialogService.openDialog('error', response.errors);
        } else {
          this.dialogService.openSnackBar('login_successful');
        }
      }
    );
    }
  }
}
