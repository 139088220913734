import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {LanguageService} from '../../../shared/language.service';
import {DialogService} from '../../../shared/dialog.service';
import {Language} from '../../../shared/language.model';

@Component({
  selector: 'app-tests-details',
  templateUrl: './tests-details.component.html',
  styleUrls: ['./tests-details.component.scss']
})
export class TestsDetailsComponent implements OnInit {
  formInitiallized = false;
  testsDetailsFormGroup: FormGroup;
  test: Test;
  languages: Language[];
  doCopy = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.languages;

    if (this.route.snapshot.params.copy !== undefined
       && this.route.snapshot.params.copy === '1') {
      this.doCopy = true;
    } else {
      this.doCopy = false;
    }

    if (this.adminService.tests === null ) {
      this.adminService.fetchTests().subscribe(
        () => {
          this.test = this.adminService.getTestById(this.route.snapshot.params.test_id);

          this.initForm();
        }
      ); } else {
      this.test = this.adminService.getTestById(this.route.snapshot.params.test_id);

      this.initForm();
    }


  }

  initForm() {


    this.testsDetailsFormGroup = new FormGroup({
      test_title: new FormControl(
        this.test ? this.test.test_title_de : '', // Value beim Aufruf
        [
          Validators.required,
          Validators.maxLength(100)// weitere Validatoren
        ]),
      test_description: new FormControl(
        this.test ? this.test.test_description_de : '', // Value beim Aufruf
        [
          Validators.required,
          Validators.maxLength(255)// weitere Validatoren
        ])
      ,
      allowed_attempts: new FormControl(
        this.test ? this.test.allowed_attempts : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ])
      ,
      give_feedback_after_each_image: new FormControl(
        this.test ? this.test.give_feedback_after_each_image : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ])
      ,
      /*
      max_offset: new FormControl(
        this.test ? this.test.max_offset : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ])
      ,

       */
      precision_offset: new FormControl(
        this.test ? this.test.precision_offset : '10', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ]),
      success_rate: new FormControl(
        this.test ? this.test.success_rate : '80', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ])
      ,
      force_settings: new FormControl(
        this.test ? this.test.force_settings : '', // Value beim Aufruf
        [])
      ,
      show_neutral_images: new FormControl(
        this.test  ? this.test.show_neutral_images : '', // Value beim Aufruf
        []),
      neutral_image_time: new FormControl(
        this.test ? this.test.neutral_image_time : '', // Value beim Aufruf
        []),
      emotional_image_time: new FormControl(
        this.test ? this.test.emotional_image_time : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ]),
      between_image_time: new FormControl(
        this.test ? this.test.between_image_time : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ]),
      max_selection_time: new FormControl(
        this.test ? this.test.max_selection_time : '', // Value beim Aufruf
        [
          Validators.required// weitere Validatoren
        ]),
      play_sound_on_start: new FormControl(
        this.test ? this.test.play_sound_on_start : '', // Value beim Aufruf
        []),
      allow_testphase: new FormControl(
        this.test ? this.test.allow_testphase : ''),
      inactive: new FormControl(
        this.test ? this.test.inactive : '', // Value beim Aufruf
        [])
      }
  );

    for (const language of this.languages) {
      if (language.short !== 'de') {
        this.testsDetailsFormGroup.addControl(
          'title_' + language.short, new FormControl(
            this.test ? this.test['test_title_' + language.short] : null,
            [
              Validators.required,
              Validators.maxLength(100)// weitere Validatoren
            ]
          )
        );
        this.testsDetailsFormGroup.addControl(
          'description_' + language.short, new FormControl(
            this.test ? this.test['test_description_' + language.short] : null,
            [
              Validators.required,
              Validators.maxLength(255)// weitere Validatoren
            ]
          )
        );
      }
    }

    console.log(this.testsDetailsFormGroup);
    this.formInitiallized = true;
  }

  onSubmit() {
    if (!this.testsDetailsFormGroup.valid) {
      // return validator errors to user.
      console.log(this.testsDetailsFormGroup);
      return;
    }
    let testId = null;
    if (this.test) {
      testId = this.test.test_id;
    }
    console.log(this.testsDetailsFormGroup);
    this.adminService.saveTestChanges(testId, this.testsDetailsFormGroup.value, this.doCopy).subscribe(
      (result) => {
        if (result.success) {
          this.adminService.fetchTests().subscribe();
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate(['/admin', 'tests']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

  onChange($event) {
    if ($event.checked) {
      // code here
    } else {
      // code here
    }
  }

  returnToTestOverview() {
    if (this.testsDetailsFormGroup.dirty) {
      this.dialogService.openDialog(
        'confirm',
        'return_to_test_overview_without_saving',
        false,
        true).afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this.router.navigate(['/admin', 'tests']).catch(err => console.log(err));
        }
      });
    } else {
      this.router.navigate(['/admin', 'tests']).catch(err => console.log(err));
    }
  }
}
