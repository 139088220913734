import { Component, OnInit } from '@angular/core';
import {Client} from "../../client";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AdminService} from "../../admin.service";
import {LanguageService} from "../../../shared/language.service";
import {DialogService} from "../../../shared/dialog.service";
import * as moment from 'moment';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  newClient: Client | null = null;
  newUser: Client | null = null;

  newClientFormGroup: FormGroup;
  deleteClient = false;
  resetClientPassword = false;
  lockClient = false;
  linkEmail = true;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newClientFormGroup = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.email
      ]),
      gender: new FormControl(null),

      birthDate: new FormControl(null),

      language: new FormControl(null, [
        Validators.maxLength(2),
        Validators.minLength(2),
      ]),
      activated: new FormControl(false),
    });
  }

  onSubmit() {
    console.log(this.newClientFormGroup);
    const errors = this.getErrors();

    if (errors.length === 0) {

console.log(moment(this.newClientFormGroup.controls.birthDate.value));
      this.adminService.newUser(
        {
          username: this.newClientFormGroup.controls.username.value,
          email: this.newClientFormGroup.controls.email.value,
          lang: this.newClientFormGroup.controls.language.value,
          gender: this.newClientFormGroup.controls.gender.value,
          birthDate:  moment(this.newClientFormGroup.controls.birthDate.value),
          activated: this.newClientFormGroup.controls.activated.value
        }
      ).subscribe(
        (response) => {
          if (response.success) {
            this.dialogService.openDialog('info', response.data[0]);
          } else {
            this.dialogService.openDialog('error', response.errors);
          }
        }
      );

    } else {

      this.dialogService.openDialog('error', errors);

    }
  }

  private getErrors() {
    const errors = [];

    if (this.newClientFormGroup.controls.username.invalid) {
      errors.push('invalid_username');
    }

    if (this.newClientFormGroup.controls.email.invalid) {
      errors.push('invalid_email');
    }

    if (this.newClientFormGroup.controls.language.invalid) {
      errors.push('invalid_language');
    }

    return errors;
  }

  onEmailInput() {
    if (this.linkEmail) {
      this.newClientFormGroup.patchValue({
        clientEmail: this.newClientFormGroup.controls.email.value
      });
    }
  }
}
