import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '../../shared/language.service';
import {DialogService} from '../../shared/dialog.service';
import {UserService} from '../user.service';
import {map, startWith} from 'rxjs/operators';
import {University} from '../../shared/client.model';
import {AppService} from '../../app.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerFormGroup;
  @ViewChild('universitySelect', {static: false}) universitySelect: ElementRef;

  universities: University[] = [
    {
      name: 'Testeuniversität',
      id: '0',
      imagePath: 'assets/img/logo.png'
    },
    {
      name: 'Testeuniversität2',
      id: '1',
      imagePath: ''
    },
  ];

  public filteredUniversities;
  isSubmitting = false;

  constructor(
    public languageService: LanguageService,
    private userService: UserService,
    private dialogService: DialogService,
    public appService: AppService
  ) {


  }

  ngOnInit() {
    this.initForm();
  }
  private initForm() {

    this.registerFormGroup = new FormGroup({
      stepTwo: new FormGroup({
        username: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20)
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.maxLength(50)
        ]),
        gender: new FormControl(null, [
          Validators.required,
          Validators.maxLength(20)
        ]),
        birthDate: new FormControl('', [
          Validators.required
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(255),
        ]),
        password2: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(255),
        ]),
        tos_privacy: new FormControl(false, [
          Validators.requiredTrue
        ])
      })
    });
  }

  public _filterUniversities(): University[] {
    const value = this.registerFormGroup.controls.client.value;
    const filterValue = value.toLowerCase();

    return this.universities.filter(
      university => {
        return university.name.toLowerCase().indexOf(filterValue) === 0;
      }
    );
  }

  onSubmit() {
    console.log(this.registerFormGroup);

    const preErrors = this.getErrors();

    if (preErrors.length === 0) {
      this.isSubmitting = true;
      const birthDateTimestamp = this.registerFormGroup.value.stepTwo.birthDate.getTime() / 1000 + 60 * 60 * 24;

      const clientId = 0; //  this.getClientIdByName(this.registerFormGroup.value.client);

      this.userService.signUp(
        clientId,
        this.registerFormGroup.value.stepTwo.username,
        this.registerFormGroup.value.stepTwo.email,
        this.registerFormGroup.value.stepTwo.password,
        this.languageService.getCurrentLanguage(),
        this.registerFormGroup.value.stepTwo.gender,
        birthDateTimestamp
      )

      .subscribe(
        response => {
          this.isSubmitting = false;

          if (!response.error) {
            this.dialogService.openDialog(
              'info',
              [...response.data, 'verification_mail_send']
            );
          } else {
            this.dialogService.openDialog(
              'error',
              response.errors.reverse()
            );
          }
        }
      );
    } else {
      this.dialogService.openDialog(
        'error',
        preErrors
      );
    }
  }
  getClientIdByName(uniName) {
    const uniIndex = this.universities.findIndex( (uni) => {
      return uni.name === uniName;
    });
    return this.universities[uniIndex];
  }

  getErrors() {
    const preErrors = [];
/*
    if (this.registerFormGroup.controls.client.invalid) {
      preErrors.push('university_must_be_valid');
    }
*/
    if (this.registerFormGroup.controls.stepTwo.controls.username.invalid) {
      preErrors.push('username_must_be_valid');
    }

    if (this.registerFormGroup.controls.stepTwo.controls.email.invalid) {
      preErrors.push('email_must_be_valid');
    }

    if (this.registerFormGroup.controls.stepTwo.controls.gender.invalid) {
      preErrors.push('gender_must_be_valid');
    }

    if (this.registerFormGroup.controls.stepTwo.controls.password.invalid) {
      preErrors.push('password_must_be_valid');
    } else if (this.registerFormGroup.value.stepTwo.password !== this.registerFormGroup.value.stepTwo.password2) {
      preErrors.push('passwords_need_to_match');
    }

    if (this.registerFormGroup.value.stepTwo.password !== this.registerFormGroup.value.stepTwo.password2) {
      preErrors.push('passwords_need_to_match');
    }

    if (this.registerFormGroup.controls.stepTwo.controls.tos_privacy.invalid) {
      preErrors.push('must_accept_tos_and_privacy');
    }

    return preErrors;
  }

}
