import { Component, OnInit } from '@angular/core';
import {Client} from '../../client';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '../../../shared/language.service';
import {DialogService} from '../../../shared/dialog.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class ClientEditComponent implements OnInit {

  editedClient: Client | null = null;
  editClientFormGroup: FormGroup;
  deleteClient = false;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit() {

    const clientId = this.route.snapshot.params.client_id;
    this.route.data.subscribe();

    if (clientId !== null && clientId !== undefined) {
      this.editedClient = this.adminService.getClientById(clientId);
    } else {
      this.editedClient = {
        client_id: null,
        client_name: '',
        client_email: '',
        locked: '0',
        user_id: null
      };
    }


    this.initForm();
  }

  private initForm() {

    this.editClientFormGroup = new FormGroup({
      clientName: new FormControl(this.editedClient.client_name, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]),
      clientEmail: new FormControl(this.editedClient.client_email, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)
      ]),
      locked: new FormControl(this.editedClient.locked === '1'),
      delete: new FormControl(false),
      resetPassword: new FormControl(false),
    });
}

  onSubmit() {

    let noErrors = true;

    if (this.editClientFormGroup.controls.delete.value) {
      this.adminService.deleteClient(this.editedClient.client_id).subscribe(
        response => {
          if (response.success) {
            this.dialogService.openSnackBar(response.data);
          } else {
            this.dialogService.openDialog('error', response.errors);
            noErrors = false;
          }
        }
      );
    }

    this.editedClient.client_name = this.editClientFormGroup.controls.clientName.value;
    this.editedClient.client_email = this.editClientFormGroup.controls.clientEmail.value;
    this.editedClient.locked = this.editClientFormGroup.controls.locked.value ? '1' : '0';

    this.adminService.editClient(this.editedClient).subscribe(
      response => {
        if (response.success) {
          this.dialogService.openSnackBar(response.data);
        } else {
          this.dialogService.openDialog('error', response.errors);
          noErrors = false;
        }
      }
    );
    if (noErrors) {
      this.router.navigate(['/admin', 'clients']);
    }
  }
}
