import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../shared/language.service';
import {UserService} from '../user/user.service';
import {DialogService} from '../shared/dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private tab: string;
  public selectedTab: number;
  constructor(
    public languageService: LanguageService,
    public userService: UserService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService
  ) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      switch (params.type) {
        case 'login':
          this.selectedTab = 1;
          break;
        case 'signup':
          this.selectedTab = 0;
          break;
        default:
          this.selectedTab = 1;
          break;
      }
    });
  }
}
