import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AdminService} from '../../admin.service';
import {User} from '../user';


@Injectable({ providedIn: 'root' })
export class EditUserResolverService implements Resolve<User> {
  constructor(
    private adminService: AdminService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.adminService.fetchUsers();
  }
}
