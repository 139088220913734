import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-circular',
  templateUrl: './progress-circular.component.html',
  styleUrls: ['./progress-circular.component.scss']
})
export class ProgressCircularComponent {
  valueInPercent: number;
  value: number;
  _color: string;
  _valueEnd: number;
  _valueCurrent: number;
  _outputString: string;
  _displayValue: string|number;
  @Input() showWhiteBackground = false;
  @Input() displayTextInstead = false;
  @Input() diameter: number;
  @Input() stroke: number;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input()
  set color(color: string){
    this._color = color;
  }
  @Input()
  set valueCurrent(value: number) {
    this._valueCurrent = value;
    this.update();
  }
  @Input()
  set valueEnd(value: number) {
    this._valueEnd = value;
    this.update();
  }
  @Input()
  set displayValue(value: string|number) {
    this._displayValue = value;
    this.update();
  }

  constructor() { }

  private update() {
    this.updateName();
    this.updateValues();
  }

  private updateName() {
    this._outputString = `${this._displayValue ? this._displayValue : this._valueCurrent}/${this._valueEnd}`;
  }

  private updateValues() {
    this.valueInPercent = this._valueCurrent / this._valueEnd;
    this.value = this.valueInPercent * 100;
  }
}
