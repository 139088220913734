import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {LanguageService} from '../../../shared/language.service';
import {DialogService} from '../../../shared/dialog.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../client';
import {User} from '../user';
import * as moment from 'moment';
import {UserService} from '../../../user/user.service';
import {AppService} from '../../../app.service';
import {Language} from '../../../shared/language.model';
import {log} from "util";

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class UserEditComponent implements OnInit {

  editedUser: User | null = null;
  editUserFormGroup: FormGroup;
  genders = null;
  languages: Language[];

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService,
    private router: Router,
    public appService: AppService
  ) { }

  ngOnInit() {

    this.genders = this.appService.genders;
    this.languages = this.languageService.languages;
    const userId = this.route.snapshot.params.user_id;
    this.route.data.subscribe();

    this.editedUser = this.adminService.getUserById(userId);
  // console.log(this.editedUser.birthdate);
	// console.log("login_attempts: "+this.editedUser.login_attempts);
    this.initForm();
  }

  private initForm() {

    this.editUserFormGroup = new FormGroup({
      username: new FormControl({value: this.editedUser.username, disabled: false}, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]),
      email: new FormControl({value: this.editedUser.email, disabled: false}, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.email
      ]),
      // project : new FormControl({value: this.editedUser.user-project, disabled: false}, [
      // ]),
      lang : new FormControl({value: this.editedUser.lang, disabled: false}, [
      ]),
      gender: new FormControl({value: this.editedUser.gender, disabled: false}, [
      ]),
      birthdate: new FormControl({value: new Date(this.editedUser.birthdate), disabled: false}, [
      ]),
      created_at: new FormControl({value: this.editedUser.created_at, disabled: true}, [
      ]),
      info_missing: new FormControl({value: this.editedUser.info_missing, disabled: false}, [
      ]),
      login_attempts: new FormControl({value: this.editedUser.login_attempts, disabled: true}, [
      ]),
      locked: new FormControl({value: this.editedUser.locked === 1, disabled: true}),
    });
  }

  onSubmit() {
    this.adminService.editUser({
      user_id: this.editedUser.user_id,
      client_id: this.editedUser.client_id,
      username: this.editUserFormGroup.controls.username.value,
      // user-project : this.editUserFormGroup.controls. user-project.value,
      email: this.editUserFormGroup.controls.email.value,
      lang: this.editUserFormGroup.controls.lang.value,
      gender: this.editUserFormGroup.controls.gender.value,
      birthdate: moment(this.editUserFormGroup.controls.birthdate.value),
      created_at: this.editedUser.created_at,
      info_missing: this.editedUser.info_missing,
      activated: this.editedUser.activated,
      login_attempts: this.editedUser.login_attempts,
      locked: this.editedUser.locked,
      lock_at: this.editedUser.lock_at
    }).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }
}
