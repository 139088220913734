import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from './shared/language.service';
import {UserService} from './user/user.service';
import {UserModel} from './user/user.model';
import {AppService} from './app.service';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: UserModel;
  @ViewChild('sidenavDrawer', {static: true}) slideNav: MatDrawer;


  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private appService: AppService
  ) {
  }
  ngOnInit(): void {
    this.userService.autoLogin().subscribe();
    this.appService.setDrawer(this.slideNav);
    this.appService.ngOnInitialLoad();
  }

}
