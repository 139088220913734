import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../shared/language.service';
import {AppService} from '../app.service';
import {Page} from '../shared/admin.model';
import {UserService} from '../user/user.service';
import {UserModel} from '../user/user.model';
import {Router} from '@angular/router';
import {Project} from '../admin/projects/project';



@Component({
  selector: 'app-slide-nav',
  templateUrl: './slide-nav.component.html',
  styleUrls: ['./slide-nav.component.scss']
})
export class SlideNavComponent implements OnInit {
  pages: Page[];
  public user: UserModel;

  constructor(
    public languageService: LanguageService,
    public appService: AppService,
    public userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.onUserChange.subscribe(
      () => {
        this.pages = this.appService.getPages();
      }
    );
    this.userService.onUserChange.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  onLogout() {
    this.userService.logout();
  }

  onToAccount() {
    this.appService.closeSideNav();
    this.router.navigate(['/account'])
      .then();
  }

  onToProjects() {
    this.appService.closeSideNav();
    this.router.navigate([ '/admin',
      'projects',
      'details'])
      .then();
  }
}
