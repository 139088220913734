import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import {LoginComponent} from './user/login/login.component';
import {RegisterComponent} from './user/register/register.component';
import {HomeComponent} from './home/home.component';

import {AdminOverviewComponent} from './admin/overview/overview.component';
import {AuthAdminGuard} from './admin/auth-admin.guard';
import {AdminClientsComponent} from './admin/clients/clients.component';
import {ClientEditComponent} from './admin/clients/edit/edit.component';
import {EditClientResolverService} from './admin/clients/edit/editClient-resolver.service';
import {NewClientComponent} from './admin/clients/new/new.component';
import {ActivateComponent} from './shared/activate/activate.component';
import {UsersComponent} from './admin/users/users.component';
import {UserEditComponent} from './admin/users/edit/edit.component';
import {EditUserResolverService} from './admin/users/edit/editUser.resolver';
import {AccountComponent} from './user/account/account.component';
import {AccountGuard} from './user/account/account.guard';
import {TestOverviewComponent} from './test-overview/test-overview.component';
import {EditPermissionsComponent} from './admin/users/edit-permissions/edit-permissions.component';
import {ImprintComponent} from './shared/footer/imprint/imprint.component';
import {PrivacyPolicyComponent} from './shared/footer/privacy-policy/privacy-policy.component';
import {TosComponent} from './shared/footer/tos/tos.component';
import {TestsComponent} from './admin/tests/tests.component';
import {ImagesComponent} from './admin/tests/images/images.component';
import {NewUserComponent} from './admin/users/new-user/new-user.component';
import {TestPreviewComponent} from './test-overview/test-preview/test-preview.component';
import {TestResolverService} from './test-overview/testResolver-resolver.service';
import {TestExecuteComponent} from './test-overview/test-execute/test-execute.component';
import {TestsDetailsComponent} from './admin/tests/tests-details/tests-details.component';
import {ViewUserResultsComponent} from './admin/users/view-user-results/view-user-results.component';
import {ProjectsComponent} from './admin/projects/projects.component';
import {ProjectsDetailsComponent} from './admin/projects/projects-details/projects-details.component';
import {UserProjectComponent} from './admin/users/user-project/user-project.component';
import {ProjectsDragComponent} from './admin/projects/projects-drag/projects-drag.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: 'activate/:linkId',
        component: ActivateComponent
      },
      {
        path: '',
        canActivate: [AccountGuard],
        children: [
          {
            path: 'tests',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    component: TestOverviewComponent,
                  },
                  {
                    path: 'preview/:testId',
                    component: TestPreviewComponent,
                    resolve: {tests: TestResolverService}
                  },
                  {
                    path: 'start/:testId',
                    component: TestExecuteComponent,
                  }
                ]
              },
            ]
          },
          {
            path: 'account',
            component: AccountComponent,
          },
        ]
      },
      {
        path: 'admin',
        canActivateChild: [AuthAdminGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: AdminOverviewComponent,

          },
          {
            path: 'projects',
            children: [
              {
                path: '',
                component: ProjectsComponent,

              },
              {
                path: 'details',
                component: ProjectsDetailsComponent
              },
              {
                path: 'drag/:project_id',
                component: ProjectsDragComponent
              },
               {
                 path: 'details/:project_id',
                 component: ProjectsDetailsComponent
               },
            ]
          },
          {
            path: 'clients',
            children: [
              {
                path: '',
                component: AdminClientsComponent,
              },
              {
                path: 'new',
                component: NewClientComponent,
              },
              {
                path: 'edit/:client_id',
                component: ClientEditComponent,
                resolve: {editedClient: EditClientResolverService}
              }
            ]
          },
          {
            path: 'tests',
            children: [
              {
                path: '',
                component: TestsComponent,
              },
              {
                path: 'images/edit/:test_id',
                component: ImagesComponent,
              },
              {
                path: 'details',
                component: TestsDetailsComponent,
              },
              {
                path: 'details/:test_id',
                component: TestsDetailsComponent,
              },
              {
                path: 'details/:test_id/:copy',
                component: TestsDetailsComponent,
              },
            ]
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                component: UsersComponent,
              },
              {
                path: 'permissions/edit/:userid',
                component: EditPermissionsComponent,
                resolve: {editedClient: EditUserResolverService}
              },
              {
                path: 'new',
                component: NewUserComponent,
              },
              {
                path: 'userproject/:userid',
                component: UserProjectComponent,
                resolve: {editedClient: EditUserResolverService}
              },
              {
                path: 'edit/:user_id',
                component: UserEditComponent,
                resolve: {editedClient: EditUserResolverService}
              },
              {
                path: 'tests/details/:user_id',
                component: ViewUserResultsComponent,
              },
              {
                path: 'tests/details/:user_id',
                component: ViewUserResultsComponent,
              },
            ]
          },
          {
            path: '**',
            component: AdminOverviewComponent
          }
        ]
      },
      {
        path: 'imprint',
        component: ImprintComponent
      },
      {
        path: 'privacypolicy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'termsofservice',
        component: TosComponent
      },
      {
        path: ':type',
        component: HomeComponent
      }
    ]
  },
  {
    path: '**',
    component: HomeComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

