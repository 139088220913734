/**
 * User Service
 *
 * Handles all user related actions, requests and authentication
 *
 * @author Vincent Menzel
 * @category controller
 * @version 1.0
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  LoginResponseModel,
  RegisterResponseModel, ServerDefaultSettingsResponseModel,
  ServerResponseModel,
  ValidateResponseModel
} from '../shared/serverresponse.model';
import {environment} from '../../environments/environment';
import {catchError, findIndex, tap} from 'rxjs/operators';
import {UserInterface, UserModel} from './user.model';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LanguageService} from '../shared/language.service';
import * as moment from 'moment';
import {Language} from '../shared/language.model';
import {UserSettings} from './account/user-settings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }
  user: UserModel = null;

  onUserChange = new BehaviorSubject(this.user);
  onInitialUser = new Subject();
  afterLogin = new BehaviorSubject<boolean>(false);

  autoLoginFinished = false;

  private static genUser(response, expirationDate) {
    const test = UserService.decodeUser(response.data.token);
    const newUser = new UserModel(
      test.username,
      test.email,
      test.lang,
      test.gender,
      test.birthdate,
      test.rights,
      response.data.token,
      test.userSettings,
      expirationDate
    );
    return newUser;
  }

  private static generateExpirationDate(lifeTime: number) {

    /**
     *  Token Expiration Date generator
     *
     * @param number lifeTime days that should be added in days
     * @return number timestamp + lifeTime
     */

    const date = new Date();
    date.setDate(date.getDate() + lifeTime);

    return +date;
  }

  private static decodeUser(token: any): UserInterface {
    return JSON.parse(atob(token.split('.')[1]).toString());
  }

  signUp(clientId, username, email, password, lang, gender, birthDate) {
    const body = new FormData();

    body.append('func', 'SIGNUP');

    body.append('clientId', clientId);
    body.append('username', username);
    body.append('email', email);
    body.append('password', password);
    body.append('lang', lang);
    body.append('gender', gender);
    body.append('birthDate', birthDate);


    //    Send Post request

    return this.httpClient.post<RegisterResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap((response) => {
          if (response.error === false) {


            //    REGISTRATION SUCCESSFUL

          } else {


            //    REGISTRATION FAILED

          }
        })
      );
  }


  signIn(email, password, stayLoggedIn = false) {

    const body = new FormData();

    body.append('func', 'SIGNIN');

    body.append('email', email);
    body.append('password', password);


    //    Send Post request

    return this.httpClient.post<LoginResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap((response) => {
          try {
            if (response.success) {


              //    Get Token expiration timestamp

              const decodedUser = UserService.decodeUser(response.data.token);
              const expirationDate = UserService.generateExpirationDate(+decodedUser.tokenValidFor);


              this.user = UserService.genUser(response, expirationDate);


              //    If user wants to stay logged in save user to localstorage @todo clear unnecessary information

              if (stayLoggedIn) {
                localStorage.setItem('token', this.user.token);
                localStorage.setItem('tokenExpiration', this.user.tokenExpiration.toString());
              }


              //    Emit new User

              this.onUserChange.next(this.user);
              this.afterLogin.next(true);

            } else {


              //    Popup Login Failed

              this.logout();
            }
          } catch (e) {
            console.log(e);
          }

        })
      );
  }

  logout() {

    //    unset user object and localstorage

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    this.user = null;


    //    emit new user as null

    this.onUserChange.next(this.user);


    //    navigate back to homepage

    this.router.navigate(['/']);

  }

  validLogin() {

    /**
     *  @return boolean
     */


    //    returns boolean weither user is validly logged in
    if (this.user !== null) {

      const currentDate = new Date();
      const now = +currentDate;
      const tokenExpiationDate = +this.user.tokenExpiration;

      if (now < tokenExpiationDate) {
        return true;
      } else {
        this.logout();
      }
    }
    return false;
  }

  hasRightTo(permissionId) {

    //    Permission check for User

    //  Find Permission
      //  searches the array for the required permission


    if (this.user !== null) {
      const result = this.user.permission.findIndex( permission => {
        return permission === permissionId;
      }) !== -1;


      //  the permission is not found => write the required permission to console

      // if (!result) {
      //   console.log('OVERSTEPPED', permissionId);
      // }

      return result;

    }

    //  user is not set return null

    return null;
  }

  autoLogin() {

    //    Auto Login
      //    runs once the application starts or the route guard is accessed

    if (this.user == null && !this.autoLoginFinished) {


      //    check the localstorage for a saved user object

      const now = new Date().getTime();
      const body = new FormData();
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      const token = localStorage.getItem('token');


      //    if found check the token expiration date

      if (tokenExpiration !== null && token !== null) {
        if (+tokenExpiration > now) {


          //    if the expiration date is valid send validation request to api

          body.append('func', 'VALIDATE');
          body.append('token', token);

          return this.httpClient.post<LoginResponseModel>(environment.ApiLocation, body)


            //    pipe the output so that the observable can still be returned and subscribed to

            .pipe(


              //    tap into the response and login if the request is successful

              tap((response) => {


              //    if token is valid

              if (response.success) {


                //    Create new User From response

                this.user = UserService.genUser(response, tokenExpiration);


                //    Emit new Value to Observable

                this.onUserChange.next(this.user);

              } else {

                //  logout if invalid

                this.logout();
              }

              //    Emit new Login State to Observables ( REQUIRED FOR THE ROUTE GUARD )
              this.afterLogin.next(true);
              this.autoLoginFinished = true;
              this.onInitialUser.next(this.user);
              this.onInitialUser.complete();
            })
          );
        } else {

          //    token past expiration date

          this.logout();
        }
      }
    }

    //    Maintain Observable => can't return null ( RETURNS OBSERVABLE THEN RESOLVES IT RIGHT AWAY )

    this.afterLogin.next(true);

    return new Observable(
      subscriber => {
        subscriber.next(false);
        subscriber.complete();
      }
    );
  }

  updatePassword(newPassword: string) {

    const body = new FormData();

    body.append('func', 'CHANGEPASSWORD');
    body.append('token', this.user.token);
    body.append('new_password', newPassword);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  updateUserDetails(brithdate: string, gender: string) {

    //  @todo ?Extend user settings so more than the username can be changed
    const body = new FormData();

    body.append('func', 'UPDATEUSERDETAILS');
    body.append('token', this.user.token);
    body.append('new_birthdate', brithdate);
    body.append('new_gender', gender);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  updateUserEmail(newEmail: string) {

    const body = new FormData();

    body.append('func', 'UPDATEUSERMAIL');
    body.append('token', this.user.token);
    body.append('new_email', newEmail);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  updateUserLanguage(lang: string) {

    if (this.validLogin()) {
      const body = new FormData();

      body.append('func', 'UPDATEUSERLANGUAGE');
      body.append('token', this.user.token);
      body.append('new_lang', lang);

      return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
    }
  }

  sendForgottenPasswordMail(email: string) {
    const body = new FormData();

    body.append('func', 'SENDRESETPASSWORDMAIL');
    body.append('email', email);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  getDefaultTestSettings() {
    const body = new FormData();

    body.append('func', 'GETDEFAULTSETTINGS');

    return this.httpClient.post<ServerDefaultSettingsResponseModel>(environment.ApiLocation, body);
  }

  updateUserSettings(params: UserSettings) {

    const body = new FormData();
    body.append('func', 'UPDATETESTSETTINGS');
    body.append('token', this.user.token);


    for (const [key, entry]  of Object.entries(params)) {
      body.append(key, entry);
    }

    return this.httpClient.post<ServerDefaultSettingsResponseModel>(environment.ApiLocation, body)
      .pipe(tap(
          result => {
            if (result.success) {
              this.user.userSettings = params;
            }
          }
      ));
  }

  resetUserTestSettings() {
    const body = new FormData();
    body.append('func', 'RESETUSERSETTINGS');
    body.append('token', this.user.token);

    return this.httpClient.post<ServerDefaultSettingsResponseModel>(environment.ApiLocation, body)
      .pipe(tap(
        result => {
          if (result.success) {
            this.getDefaultTestSettings().subscribe(
              (response) => {
                this.user.userSettings = response.data;
                this.onUserChange.next(this.user);
              }
            );
          }
        }
      ));
  }
}
