import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../language.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Language} from '../language.model';
import {UserModel} from '../../user/user.model';
import {UserService} from '../../user/user.service';
import {AdminService} from '../../admin/admin.service';
import {Page} from '../admin.model';
import {BreakpointObserver} from "@angular/cdk/layout";
import {AppService} from "../../app.service";
import {DialogService} from "../dialog.service";
import {MatToolbar} from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('header', {static: false}) header: MatToolbar;
  langSubscription: Subscription;
  lang: Language[];
  user: UserModel;
  selectedLanguage: Language;
  languages: Language[];

  viewHamburgerMenuInstead: boolean;

  public pages: Page[];
  public adminPages: Page[];
  drawerState: boolean;

  // tslint:disable-next-line:variable-name
  private _Subscriptions: Subscription[] = [];

  constructor(
    public languageService: LanguageService,
    public userService: UserService,
    private adminService: AdminService,
    private breakpointObserver: BreakpointObserver,
    public appService: AppService,
    private dialogService: DialogService
  ) {}

  ngAfterViewInit(): void {
    const newHeader = {width: +this.header._elementRef.nativeElement.clientWidth, height: +this.header._elementRef.nativeElement.clientHeight};
     //console.log(newHeader);
    this.appService.header.next(newHeader);
  }

  ngOnInit() {



    this._Subscriptions.push(this.appService.drawerStateChange.subscribe(
      (state) => {
        this.drawerState = state;
      }
    ));

    this._Subscriptions.push(this.userService.onUserChange.subscribe(
      () => {
        this.adminPages = this.appService.getAdminPages();
        this.pages = this.appService.getPages();
      }
    ));


    this._Subscriptions.push(this.appService.onBreakpointChanges.subscribe(() => {
      this.viewHamburgerMenuInstead = this.appService.isSmallerThanMd;
    }));

    this.languages = this.languageService.languages;

    this._Subscriptions.push(this.langSubscription = this.languageService.onLanguageChange.subscribe(
      (newLang) => {
        //    Update to new Language
        const index = this.languages.findIndex(item => {
          return item.short === newLang;
        });
        this.selectedLanguage = this.languages[index];
      }
    ));
    this._Subscriptions.push(this.userService.onUserChange.subscribe(
      user => {
        this.user = user;
      }
    ));


  }
  onChangeLanguage(newLanguage) {
    this.languageService.setLanguage(newLanguage);

    this.languages.forEach(
      lang => {
        if (lang.short === newLanguage) {
          this.selectedLanguage = lang;
        }
      }
    );

    if (this.userService.validLogin()) {
      this.userService.updateUserLanguage(newLanguage).subscribe(
        result => {
          if (!result.success) {
            this.dialogService.openDialog('error', result.errors);
          }
        }
      );
    }
  }

  onResize(event: Event) {
    console.log(event);
    console.log(this.header);
    this.appService.header.next({height: 0, width: 0});
  }

  onLogout() {
    this.userService.logout();
  }


  ngOnDestroy() {
    this._Subscriptions.forEach(item => item.unsubscribe());
  }
}
