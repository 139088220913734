import {Injectable} from '@angular/core';
import {languages, locales} from '../../assets/js/languages';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Language} from './language.model';
import {UserService} from '../user/user.service';
import {AppService} from '../app.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    currentLang: string;

    languages: Language[] = [];
    selectedLanguage: Language;

    onLanguageChange = new BehaviorSubject(this.getCurrentLanguage());

    userSub: Subscription;

    constructor(
        private userService: UserService,
        private appService: AppService
    ) {
        for (const language of languages) {
            this.languages.push(new Language(language.name, language.short));
        }
        this.userSub = this.userService.onUserChange.subscribe(
            user => {
                if (user !== null) {
                    this.setLanguage(user.lang);
                }
            }
        );
    }
    public lang(localeId) {
        let currentLang = localStorage.getItem('lang');

        if (currentLang === null || currentLang === undefined) {
            this.setLanguage(this.appService.defaultLanguage);
            currentLang = this.getCurrentLanguage();
        }

        currentLang.toLowerCase();
        const returnLocale = locales[currentLang][localeId];
        if (returnLocale === '' || returnLocale == null) {
            return '#(' + localeId + ')';
        }
        return returnLocale;
    }
    public getCurrentLanguage() {
        const currentLang = localStorage.getItem('lang');

        if (!!currentLang) {
            return currentLang;
        } else {
            localStorage.setItem('lang', 'de');
            return 'de';
        }
    }

    public setLanguage(newLang: string): void {
        newLang = newLang.toLowerCase();
        if (newLang in locales) {
            localStorage.setItem('lang', newLang);
        } else {
            localStorage.setItem('lang', this.appService.defaultLanguage);
        }
    }
}
