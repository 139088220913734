/**
 * Dialog Service
 *
 * Handles all dialog, popup, snackbar and bottom paper related actions
 *
 * @author Vincent Menzel
 * @category controller
 * @version 1.0
 */

import { Injectable } from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogComponent} from './dialog/dialog.component';
import {LanguageService} from './language.service';
import {SelectTimeComponent} from './dialogs/select-time/select-time.component';
import {CustomHtmlDialogComponent} from "./dialogs/custom-html-dialog/custom-html-dialog.component";
import {TestTestphaseResultsComponent} from "./dialogs/test-testphase-results/test-testphase-results.component";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private languageService: LanguageService
  ) {
  }

  openDialog(dialogTitle, dialogContent, useRaw = false, okCancel = false) {
    return this.dialog.open(DialogComponent, {
      data: {
        title: dialogTitle,
        content: dialogContent,
        useRaw,
        okCancel
      }
    });
  }

  openSelectTime(dialogTitle, dialogContent, okCancel = false, minDate = null, maxDate: Date = null) {
    return this.dialog.open(SelectTimeComponent, {
      data: {
        title: dialogTitle,
        content: dialogContent,
        okCancel,
        minDate,
        maxDate
      }
    });
  }


  openCustomHtmlDialog(dialogTitle, dialogContent) {
    return this.dialog.open(CustomHtmlDialogComponent, {
      data: {
        title: dialogTitle,
        content: dialogContent
      }
    });
  }


  openTestTestphaseSubresultDialog(title, selectedEmotion, selectedIntensity, emotion, intensity) {
    return this.dialog.open(TestTestphaseResultsComponent, {
      data: {
        title,
        selectedEmotion,
        selectedIntensity,
        emotion,
        intensity,
      }
    });
  }

  openSnackBar(message: string, durationInSec: number = 2.5) {
    this.snackBar.open(this.languageService.lang(message), this.languageService.lang('close'), {
      duration: durationInSec * 1000,
    });
  }
}
