import {Component, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../../shared/language.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {DialogService} from '../../../shared/dialog.service';
import {TestService} from '../test.service';
import {ImagesService} from './images.service';
import {Client} from '../../client';
import {DomSanitizer} from "@angular/platform-browser";
import {MatTableDataSource} from "@angular/material/table";
import {getBindingElementVariableDeclaration} from "tslint";



@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {


  test: Test;
  newFotosFormGroup: FormGroup;
  @ViewChild('imageUpload', {static: true}) imageUpload: any;
  @ViewChild('form', {static: true}) Form;
  images = [];
  displayedColumns: string[] = ['file_name', 'image_emotional', 'image_neutral', 'emotion', 'emotion_intensity'];
  loading = false;
  dataSource = new MatTableDataSource<{image_emotional: string, image_neutral: string, image_id: string, emotion: string, emotion_intensity: string}[]>(this.images);

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private adminService: AdminService,
    public languageService: LanguageService,
    private dialogService: DialogService,
    private testService: TestService,
    private imagesService: ImagesService
  ) {
  }

  ngOnInit() {
    this.newFotosFormGroup = this.formBuilder.group({
      image_id: [],
      client_id: [],
      image_neutral: [],
      image_emotional: [],
      emotion: [],
      file_name: ['', Validators.required]
    });
    this.onShowImages();
  }

  onSubmit() {
   // console.log(this.newFotosFormGroup.value);

  if (this.imageUpload.nativeElement.files[0] === undefined)  {
    this.dialogService.openDialog('no_file','no_file_selected');
    return;
  }
  this.dialogService.openDialog('confirm', ['this_will_replace_current_images'], false, true).afterClosed().subscribe(
      response => {
        if (response) {
          this.imagesService.newImg(this.imageUpload.nativeElement.files[0], this.route.snapshot.params.test_id).subscribe(
            (result) => {
              if (result.success) {
                this.dialogService.openSnackBar(result.data[0]);

                if (result.errors.length !== 0) {
                  this.dialogService.openDialog('error', result.errors).afterClosed().subscribe(() => {
                    this.router.navigate(['/admin', 'tests']);
                  });
                } else {
                  this.router.navigate(['/admin', 'tests']);
                }

              } else {
                this.dialogService.openDialog('error', result.errors);
              }
            }
          );
        }
      }
    );
  }

  onShowImages() {
    if (this.images && this.images.length === 0) {
      this.loading = true;
      this.adminService.fetchImagesForTestId(this.route.snapshot.params.test_id).subscribe(
        result => {

          if (result.success) {
            for (const entry of result.data.images) {
              this.images.push(
                {
                  image_emotional: this.sanitizer.bypassSecurityTrustResourceUrl(entry.image_emotional),
                  image_neutral: this.sanitizer.bypassSecurityTrustResourceUrl(entry.image_neutral),
                  image_id: entry.image_id,
                  file_name: entry.file_name,
                  emotion: entry.emotion,
                  emotion_intensity: entry.emotion_intensity
                }
              );
            }
          }

          this.dataSource = new MatTableDataSource<{image_emotional: string, image_neutral: string, image_id: string, emotion: string, emotion_intensity: string}[]>(this.images);
          this.loading = false;
        }
    );
    }
  }
}
