import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../shared/language.service';
import {UserService} from '../user/user.service';
import {TestService} from '../admin/tests/test.service';
import {ActivatedRoute} from '@angular/router';
import {isString} from 'util';
import {AdminService} from '../admin/admin.service';
import {Language} from '../shared/language.model';
import {AppService} from '../app.service';


@Component({
  selector: 'app-test-overview',
  templateUrl: './test-overview.component.html',
  styleUrls: ['./test-overview.component.scss']
})
export class TestOverviewComponent implements OnInit {
  public tests: Test[];
  public filteredTests = this.tests;
  filterValue = '';

  constructor(
    public languageService: LanguageService,
    private appService: AppService,
    private userService: UserService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private testService: TestService) {
  }




  ngOnInit() {
    this.testService.onTestsChange.subscribe(
      result => {
        this.tests = result;
        this.filteredTests = this.tests;
      }
    );

    this.languageService.onLanguageChange.subscribe(
      () => {
        this.testService.fetchTestOverview().subscribe();
      }
    );

    if (this.tests === null) {
          this.testService.fetchTestOverview().subscribe();
      }
  }

  applyFilter() {
    this.filterValue.trim().toLowerCase();

    this.filteredTests = this.tests.filter((test) => {
      return (isString(test.email) ? test.email.toLowerCase().indexOf(this.filterValue) !== -1 : false)
          || (isString(test.username) ? test.username.toLowerCase().indexOf(this.filterValue) !== -1 : false)
          || (isString(test.test_title) ? test.test_title.toLowerCase().indexOf(this.filterValue) !== -1 : false);
    });

  }
}
