import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog.service';
import {LanguageService} from '../../../shared/language.service';
import {User} from '../user';
import {AdminService} from '../../admin.service';
import {UserService} from '../../../user/user.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-permissions',
  templateUrl: './edit-permissions.component.html',
  styleUrls: ['./edit-permissions.component.scss']
})
export class EditPermissionsComponent implements OnInit {

  public editedUser: User;

  userRoles = [];
  availableRoles = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private adminService: AdminService,
    private userService: UserService
  ) { }

  ngOnInit() {
    const userId = this.route.snapshot.params.userid;
    this.route.data.subscribe();

    this.editedUser = this.adminService.getUserById(userId);

    this.adminService.fetchUserRightsFor(this.editedUser.user_id).subscribe(
        result => {
          if (result.success) {
            this.userRoles = result.data.roles;
            this.availableRoles = result.data.availableRoles;
          }
        }
      );
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onSubmit() {
    this.adminService.updateUserRoles(this.editedUser.user_id, this.userRoles).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate(['/admin', 'users']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }
}
