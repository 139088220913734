/**
 * Language and Locale Model
 *
 * Holds the Language and Locales Model
 *
 * @author Vincent Menzel
 * @category controller
 * @version 1.0
 */

export class Language {
  constructor(
    public name: string,
    public short: string
  ) {}

}
