import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AdminService} from '../../admin.service';
import {Client} from '../../client';


@Injectable({ providedIn: 'root' })
export class EditClientResolverService implements Resolve<Client> {
  constructor(
    private adminService: AdminService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.adminService.fetchClients();
  }
}
