
export const locales = {
"de": {
        access_forbidden: "Zugriff verboten",
    account: "Account",
    account_is_locked: "Der Zugang ist gesperrt.",
    actions: "Aktionen",
    activated: "Aktiviert",
    admin_client: "Clientenverwaltung",
    admin_client_desc: "Clienten Übersicht, Clienten sperren, Clienten bearbeiten...",
    admin_overview: "Adminübersicht",
    admin_overview_desc: "Admin Projektübersicht",
    admin_page: "Admin Seite",
    admin_projects: "Projektverwaltung",
    admin_test_desc: "Verwaltung der Tests",
    admin_tests: "Testverwaltung",
    admin_users: "Nutzerverwaltung",
    admin_users_desc: "Nutzer Übersicht, Nutzer sperren",
    allow_test_phase: "Mit Testphase beginnen",
    allowed_attempts: "Zugelassene Versuche",
    allowed_attempts_hint: "Anzahl Wiederholungen des Tests",
    almost_done: "Fast geschafft",
    an_emotional_image_didnt_provide_any_data: "Ein Emotionales Bild beinhaltete keine Informationen",
    anger: "Ärger",
    are_you_sure: "Sind Sie sicher?",
    are_you_sure_to_reset_password: "Passwort zurücksetzen",
    attempt: "Versuch",
    attempts: "Versuche",
    available_project_tests: "verfügbare Projekttests",
    available_user_roles: "Verfügbare Rollen",
    avg_difference: "Durchschn. Abweichung",
    avg_input_passed: "Richtig erkannte in",
    avg_passed_images: "Durchschnittlich Bilder bestanden",
    avg_time_to_answer: "Durchschn. Antwortzeit",
    back_to_test_overview: "Zurück zu Testübersicht",
    bank_bic: "MARKDEF1630",
    bank_details: "Bankverbindung",
    bank_details_name: "Kasse der Universität Ulm",
    bank_iban: "DE72 6300 0000 0063 0015 05",
    bank_name: "Bundesbank Ulm",
    between_image_time: "Zeit zwischen Bildern",
    bic: "BIC (SWIFT-Code):",
    birthdate: "Geburtstag",
    can_only_be_changed_by_admin: "Kann nur von einem Administrator geändert werden",
    cancel: "Abbrechen",
    cant_move_uploaded_file: "Kann die Datei nicht im Dateisystem verschieben",
    change_email: "Email ändern",
    change_password: "Passwort ändern",
    change_test_settings: "Test Einstellungen bearbeiten",
    change_username: "Benutzernamen bearbeiten",
    chief_adress: "Adresse des Kanzlers",
    chief_name: "Kanzler Dieter Kaufmann",
    chief_street: "Helmholtzstrasse 16",
    choosen_emotion: "Gewählte Emotion",
    choosen_emotion_intensity: "Gewählte Emotionsintensität",
    click_to_preview_emotional_image: "Vorschau des emotionalen Bildes",
    click_to_preview_neutral_image: "Vorschau des neutralen Bildes",
    click_to_show_images: "Bilder anzeigen",
    client_create_new: "Anlegen",
    client_email: "Clienten Email",
    client_name: "Clienten Name",
    client_name_hint: "Name",
    client_save_changes: "Änderungen speichern",
    close: "schließen",
    Conception: "Konzeption, Realisierung und Gestaltung",
    Conception_company: "CodenGround AG",
    confirm: "Bestätigen",
    connection_failed: "Verbindung fehlgeschlagen",
    content: "Inhalt",
    content_footer_hint: "Hinweise hierzu finden Sie im Footer Menü.",
    content_question_hint: "Bitte wenden Sie sich bei Fragen an den dort genannten Verantwortlichen unter der angegebenen E-Mail-Adresse oder unter der Adresse der Universität Ulm, Helmholtzstr. 16, 89081 Ulm.",
    content_responsibility: "Die inhaltliche Verantwortung der WWW-Seiten einzelner Einrichtungen bzw. Bereiche der Universität liegt bei den jeweiligen informationseinstellenden Einrichtungen.",
    cookies: "2. Cookies",
    cookies_paragraph_1: "Zusätzlich zu den vorgenannten Daten werden beim Besuch unserer Webseite Session-Cookies auf Ihrem Rechner gespeichert (Rechtsgrundlage für die Verwendung ist Art. 6 Abs. 1 lit. f DSGVO). Unter einem Cookie wird eine Textdatei verstanden, die beim Besuch der Webseite erstellt und auf Ihrer Festplatte gespeichert wird. Bei erneutem Aufruf der Webseite wird der empfangene Cookie vom Browser der Webseite wieder zurück an den Server gesendet. Dadurch kann der Server bestimmte Informationen auswerten. Session-Cookies werden nach Beendigung des Browsers automatisch gelöscht.",
    cookies_paragraph_2: "Mit Cookies ist es nicht möglich, Programme zu starten oder Viren auf einen Computer zu übertragen. Wir setzen Cookies ausschließlich ein, um unsere Webseite nutzerfreundlicher zu gestalten und um eine Analyse des Nutzerverhaltens zu ermöglichen, so werden Session-Cookies unter anderem für Funktionalitäten wie Login, Powermail und die Webanalysesoftware Google Analytics benötigt.",
    cookies_paragraph_3: "Sie können die Verwendung von allen oder nur bestimmten Cookies über die Einstellung Ihres Browsers einschränken oder deaktivieren. Die Hilfefunktion Ihres Browsers gibt Ihnen dazu eine Anleitung. Bitte beachten Sie, dass bei einer Deaktivierung von Cookies die Funktionalität dieser Webseite eingeschränkt sein kann.",
    copy: "Kopieren",
    copyright: "Copyright",
    copyright_text: "Alle auf dieser Website veröffentlichten Inhalte (Layout, Texte, Bilder, Grafiken usw.) unterliegen dem Urheberrecht. Jede vom Urheberrechtsgesetz nicht zugelassene Verwertung bedarf vorheriger ausdrücklicher, schriftlicher Zustimmung der Universität Ulm. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Fotokopien und Downloads von Web-Seiten für den privaten, wissenschaftlichen und nicht kommerziellen Gebrauch dürfen hergestellt werden.Die Universität Ulm erlaubt ausdrücklich und begrüßt das Zitieren der Dokumente und Webseiten sowie das Setzen von Links auf ihre Website.Die Universität Ulm ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihr selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!",
    could_not_assign_role: "Fehler beim zuweisen der Standardrolle",
    create_client: "Neuer Client",
    create_new_user: "Anlegen",
    create_project: "Projekt erstellen",
    create_test: "Neuer Test",
    create_user: "Benutzer erstellen",
    created_at: "Erstellt am",
    current_images: "aktuelle Bilder",
    current_level: "Level",
    current_project_tests: "Aktuelle Projekttests",
    current_user_roles: "Aktuelle Rollen",
    data_protection_officer: "I. Name und Anschrift des Verantwortlichen und Kontakt Datenschutzbeauftragte",
    data_protection_officer_fax: "+49 (0)731/50-22038",
    data_protection_officer_law: "Verantwortlicher gem. Art. 4 Abs. 7 DSGVO ist die",
    data_protection_officer_paragraph_1: "Die Universität Ulm ist eine Körperschaft des öffentlichen Rechts, die durch den Präsidenten Prof. Dr.-Ing. Michael Weber (praesident(at)uni-ulm.de) oder durch den Kanzler Dieter Kaufmann (kanzler(at)uni-ulm.de) vertreten wird.",
    data_protection_officer_paragraph_2: "Bei Fragen rund um den Datenschutz wenden Sie sich bitte an die Datenschutzbeauftragte unter dsb(at)uni-ulm.de oder senden einen Brief mit dem Zusatz \"Datenschutzbeauftragte\" an die o. g. Adresse.",
    data_protection_officer_phone: "+49 (0)731/50-10",
    db_save_error: "Fehler beim Speichern",
    db_save_success: "Speichern Erfolgreich",
    db_save_successful: "In Datenbank gespeichert",
    delete_client: "Client löschen",
    delete_user: "Benutzer Löschen",
    delete_user_are_you_sure: "Sicher das Sie den Benutzer löschen wollen?",
    description: "Beschreibung",
    difference: "Differenz",
    disclaimer: "Haftungsausschluss",
    disclaimer_extern: "Haftungsausschluss bei Links zu externen Inhalten",
    disclaimer_extern_text: "Die Universität Ulm ist als Inhaltsanbieter für die eigenen Inhalte, die sie zur Nutzung bereithält, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise („externe Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Diese fremden Inhalte stammen weder von der Universität Ulm, noch hat die Universität Ulm die Möglichkeit, den Inhalt von Seiten Dritter zu beeinflussen. Die Inhalte fremder Seiten, auf die die Universität Ulm mittels Links hinweist, spiegeln nicht die Meinung der Universität Ulm wider, sondern dienen lediglich der Information und der Darstellung von Zusammenhängen. Die Universität macht sich diese weitervermittelten Inhalte nicht zu eigen und übernimmt auch keine Verantwortung für diese fremden Inhalte. Diese Feststellungen gelten für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in Gästebüchern, Diskussionsforen und Mailinglisten, die von der Universität Ulm eingerichtet wurden. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.",
    disclaimer_own: "Haftungsausschluss bei eigenen Inhalten",
    disclaimer_own_text: "Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und implementiert. Fehler im Bearbeitungsvorgang sind dennoch nicht auszuschließen. Eine Haftung für die Richtigkeit, Vollständigkeit und Aktualität dieser Webseiten kann trotz sorgfältiger Prüfung nicht übernommen werden. Die Universität Ulm übernimmt insbesondere keinerlei Haftung für eventuelle Schäden oder Konsequenzen, die durch die direkte oder indirekte Nutzung der angebotenen Inhalte entstehen.",
    disgust: "Ekel",
    diverse: "Divers",
    edit_permissions: "Berechtigungen bearbeiten",
    edit_permissions_of: "Berechtigungen bearbeiten",
    edit_tests_of: "Tests bearbeiten",
    edited: "Bearbeiten",
    email: "Email",
    email_communication: "4. E-Mail-Kommunikation",
    email_communication_paragraph_1: "Auf der Webseite haben Sie in der Regel die Möglichkeit über die hinterlegten E-Mail-Adressen mit den Ansprechpartnern der Universität Ulm Kontakt aufzunehmen. In diesem Fall werden die von Ihnen übermittelten personenbezogenen Daten gespeichert. Diese Daten werden von der Universität Ulm ausschließlich für die Verarbeitung der Konversation verwendet und nach Erreichen des Zwecks gelöscht. Rechtsgrundlage ist Ihre Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.",
    email_communication_paragraph_2: "Sie haben das Recht der Nutzung dieser Daten jederzeit mit Wirkung für die Zukunft zu widersprechen.",
    email_hint: "E-Mailadresse eintragen",
    email_must_be_valid: "Email ungültig",
    email_template_not_found: "Emailtemplate nicht gefunden",
    emotion: "Emotion",
    emotion_from_image: "Emotion aus Bild",
    emotion_intensity: "Emotionsintensität",
    emotional_image: "Emotionales Bild",
    emotional_image_time: "Emotionale Bilder Anzeigezeit",
    emotional_images_time: "Emotionale Bilder Anzeigezeit",
    emotional_images_will_be_shown_for: "Emotinale Bilder werden für",
    enter_user_information: "Nutzerinformationen eingeben",
    equal: "Gleich",
    error: "Fehler",
    error_when_saving: "Beim speichern ist ein Fehler aufgetreten",
    explanation: "Einführung",
    export_csv: "CSV-Export",
    export_excel: "Exportieren Excel",
    fail_animations: "Animation fehlschlagen",
    failed_to_update_tests_images_relations: "Fehler beim aktualisieren der Test - Bild Relationen ",
    fax: "Telefax",
    fear: "Angst",
    feeltest_title: "Universität Ulm - Feeltest",
    female: "Weiblich",
    female_male: "Weibliche und männliche Schreibweise",
    female_male_text: "Aus Gründen der besseren Lesbarkeit wurde in der Regel die männliche Schreibweise verwendet. Wir weisen an dieser Stelle ausdrücklich darauf hin, dass sowohl die männliche als auch die weibliche Schreibweise für die entsprechenden Beiträge gemeint ist.",
    file_name: "Dateiname",
    filter_entries: "Einträge Filtern",
    filter_tests: "Tests filtern",
    finished: "Beendet",
    for: "für",
    force_settings: "Einstellungen erzwingen",
    force_settings_false: "Testeinstellungen freigegeben",
    force_settings_true: "Testeinstellungen gesperrt",
    gender: "Geschlecht",
    gender_must_be_valid: "Geschlecht ungültig",
    general_data_processing: "II. Allgemeines zur Datenverarbeitung",
    general_data_processing_collecting: "1. Erhebung personenbezogener Daten bei der Bereitstellung der Homepage und Speicherung der Server-Logfiles",
    general_data_processing_collecting_text: "Bei einer rein informativen Nutzung unserer Webseite, erheben wir diejenigen personenbezogenen Daten, die ihr Browser an unseren Server übermittelt und die technisch erforderlich sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität sowie Sicherheit zu gewährleisten (Rechtsgrundlage ist dabei Art. 6 Abs. 1 S. 1 lit. f DSGVO)",
    general_data_processing_server_log: "Die Speicherung der vorgenannten Daten in sogenannten Server-Logfiles erfolgt, um die Funktionsfähigkeit der Webseite sicherzustellen und dient darüber hinaus zur Optimierung der Webseite und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Die gespeicherten IP-Adressen werden jede Nacht durch kürzen auf die letzten beiden Domainteile anonymisiert. Nutzerprofile werden nicht erstellt. Die gekürzten IP-Adressen und alle anderen Daten werden einmal im Monat statistisch ausgewertet und nach 35 Tagen gelöscht. Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung in Server-Logfiles ist für den Betrieb der Webseite zwingend erforderlich. Es besteht für den Nutzer keine Widerspruchsmöglichkeit.",
    general_data_subject: "III. Ihre Rechte als Betroffener",
    general_data_subject_paragraph_1: "Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten",
    general_data_subject_paragraph_2: "Darüber hinaus haben Sie das Recht, sich bei der Datenschutzaufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Die zuständige Aufsichtsbehörde ist der Landesbeauftragte für Datenschutz und Informationsfreiheit Baden-Württemberg.",
    give_feedback_after_each_image: "Feedback nach jedem Bild",
    google_analytics: "3. Google Analytics",
    google_analytics_paragraph_1: "Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. (\"Google\"). Google Analytics verwendet sog. Cookies, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",
    google_analytics_paragraph_2: "Auf dieser Webseite ist die IP-Anonymisierung aktiviert. Ihre IP-Adresse wird von Google deshalb innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übertragung gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.",
    google_analytics_paragraph_3: "Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseiteaktivitäten zusammenzustellen und um weitere mit der Webseitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",
    grief: "Trauer",
    iban: "IBAN:",
    image: "Bild",
    image_upload: "Bilder hochladen",
    imprint: "Impressum",
    inactive: "Inaktiv",
    info: "Info",
    intensity: "Intensität",
    intensity_from_image: "Intensitäts Vorgabe",
    invalid_login_credentials: "Anmeldedaten Fehlerhaft",
    it: "Technik",
    it_responsible: "Bei technischen Fragen oder Problemen wenden Sie sich bitte per E-Mail an den",
    joy: "Freude",
    just_one_click_left: "Nur noch ein KLick ",
    lang: "Sprache",
    language: "Sprache",
    language_dosnt_exist: "Ungültige Sprache",
    leave_empty_to_not_change: "Nicht ausfüllen um nicht zu ändern",
    link_already_used: "Der Link wurde schon benutzt.",
    link_dosnt_exist: "Link existiert nicht oder ist ungültig",
    lock_client: "Client sperren",
    lock_user: "Benutzer sperren",
    lock_user_at: "Ablaufdatum setzen",
    lock_user_canceled: "Nutzer sperren wurde abgebrochen",
    locked: "Gesperrt",
    login: "Einloggen",
    login_attempts: "Anmeldeversuche",
    login_failed: "Anmeldung Fehlgeschlagen",
    login_is_invalid: "Login ungültig",
    login_successful: "Anmeldung erfolgreich",
    logout: "Ausloggen",
    mail_cant_be_send: "Email konnte nicht versendet werden",
    male: "Männlich",
    max_offset: "maximaler Versatz",
    max_offset_hint: "maximaler Versatz",
    max_selection_time: "Zeit um ein Ergebnis zu wählen",
    more: "mehr",
    must_accept_tos_and_privacy: "AGB und Datenschutzbestimmungen müssen aktzeptiert werden",
    must_be_logged_in: "Sie müssen angemeldet sein",
    my_account: "Mein Account",
    name: "Name",
    neutral_image: "Neutrales Bild",
    neutral_image_time: "Neutrale Bilder Anzeigezeit",
    neutral_images_will_be_shown_for: "Neutrale Bilder werden für",
    next: "Weiter",
    no: "Nein",
    no_data_found: "Keine Daten gefunden",
    no_file: "Keine Datei",
    no_file_selected: "Sie haben keine Datei gewählt",
    no_images_yet: "Noch keine Bilder",
    no_test_round_after_attempt: "Kein Test nach Versuch",
    no_user_with_that_email_found: "Kein Benutzer mit dieser Email gefunden",
    no_zip: "Keine ZIP-Datei",
    not_all_pictures_are_pairs: "Nicht alle Bilder sind Paare",
    not_selected: "keine Auswahl",
    now_go_to_next_level: "Jetzt gehts weiter zum nächsten Level",
    offset: "Versatz",
    okey: "OK",
    open_app: "Möchten Sie die App öffnen?",
    other_bank_hint: "(Zahlungen aus dem Ausland / Einzahlungen von Studienbeiträgen und -gebühren)",
    other_banks: "Weitere Bankverbindungen",
    passed: "Bestanden",
    password: "Passwort",
    password_forgotten: "Passwort vergessen",
    password_must_be_valid: "Passwort ungültig",
    password_repeat: "Passwort wiederholen",
    password2: "Passwort wiederholen",
    pdf: "PDF",
    personal_data: "Persöhnliche Daten",
    personal_data_desc: "Persöhnliche Daten bearbeiten",
    phone: "Telefon",
    play_sound_on_start: "Startsound abspielen",
    please_note: "Bitte beachten Sie",
    precision_offset: "Maximale Abweichung",
    precision_offset_hint: "Maximale erlaubte Abweichung der Emotionsintensität, damit die Auswahl eines Benutzers als korrekt erkannt wird.",
    preparing_test: "Bereite Test vor",
    president_adress: "Adresse des Präsidenten",
    president_name: "Präsident Prof. Dr.-Ing. Michael Weber",
    president_street: "Helmholtzstrasse 16",
    previous: "Zurück",
    privacy_policy: "Datenschutz",
    privacy_policy_changes: "IV. Änderung der Datenschutzbestimmungen",
    privacy_policy_changes_paragraph_1: "Damit unsere Datenschutzbestimmungen stets den aktuellen rechtlichen Anforderungen entsprechen, behalten wir uns vor, unsere Datenschutzbestimmungen gelegentlich anzupassen. Zu Ihrem Schutz empfehlen wir Ihnen, die Datenschutzbestimmungen regelmäßig zu lesen. Durch die weitere Nutzung unserer Webseite erklären Sie sich mit unseren Datenschutzbestimmungen und deren Aktualisierung einverstanden.",
    privacy_policy_description: "Im Folgenden informieren wir Sie über die Erhebung personenbezogener Daten bei Nutzung unserer Webseite. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mailadresse, Nutzerverhalten.",
    privacy_policy_description_addition: "Diese Datenschutzerklärung ist in deutscher und englischer Sprache verfügbar. Bei Unstimmigkeiten oder Widersprüchen zwischen der deutschen und der englischen Fassung hat die deutsche Fassung Vorrang.",
    project: "Projekt",
    project_description: "Projektbeschreibung",
    project_description_hint: "Projektbeschreibungshinweis",
    project_id: "Projekt ID",
    project_save_changes: "Projekt speichern Änderungen",
    project_title: "Projekttitel",
    project_title_hint: "Projekttitel Hinweis",
    projects: "Projektverwaltung",
    publisher: "Herausgeber",
    publisher_address_fax: "+49 (0)731/50-22038",
    publisher_address_phone: "+49 (0)731/50-10",
    publisher_address_uSt: "DE173703203",
    ready_to_start_the_test: "Bereit den Test zu starten?",
    register: "Account erstellen",
    regulation_ministry: "Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg",
    regulation_ministry_location: "70173 Stuttgart",
    regulation_ministry_street: "Königstraße 46",
    regulation_responsible: "Zuständige Aufsichtsbehörde",
    remove_timer: "Ablaufdatum entfernen",
    representation: "Vertretung",
    representation_text: "Die Universität Ulm ist eine Körperschaft des öffentlichen Rechts. Sie wird durch den Präsidenten Prof. Dr.-Ing. Michael Weber oder durch den Kanzler Dieter Kaufmann vertreten.",
    request_access_state: "Zugriffsstatus / HTTP-Statuscode",
    request_browser: "Browsertyp",
    request_browser_language: "Sprache und Version der Browsersoftware",
    request_content: "Inhalt der Anforderung (Zugriffsadresse der konkreten Seite)",
    request_date_and_time: "Datum und Uhrzeit der Anfrage",
    request_ip_adress: "IP-Adresse",
    request_os: "Betriebssystem und dessen Oberflächensoftware",
    request_referer_address: "\"Referer\"-Adresse, falls vorhanden)",
    request_timezone: "Zeitzonendifferenz zur Greenwich Mean Time (GMT)",
    request_volume: "Umfang der übermittelten Daten (in Bytes)",
    reset_login: "Loginversuche zurücksetzen",
    reset_password: "Passwort zurücksetzen",
    reset_settings: "Einstellungen zurücksetzen",
    reset_test_settings_to_default: "Sicher das die Testeinstellungen zurückgesetzt werden sollen?",
    return_to_available_tests: "Verfügbare Tests",
    return_to_project_overview_without_saving: "Rückkehr zur Projektübersicht ohne Speichern",
    return_to_test_overview_without_saving: "Änderungen verwerfen und zurück zur übersicht?",
    right_of_erasure: "Recht auf Löschung gespeicherter personenbezogener Daten",
    right_of_erasure_addition: "soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist (Art. 17 DSGVO)",
    right_of_information: "Recht auf Auskunft",
    right_of_information_addition: "über die bei uns zu Ihrer Person gespeicherten Daten (Art. 15 DSGVO)",
    right_of_portability: "Recht auf Datenübertragbarkeit",
    right_of_portability_addition: "(Art. 20 DSGVO)",
    right_of_processing: "Recht auf Widerspruch gegen eine Verarbeitung",
    right_of_processing_addition: "die unserem berechtigten oder einem öffentlichen Interesse dient, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Art. 21 DSGVO); erteilte Einwilligungen zur Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten jederzeit mit Wirkung für die Zukunft zu widerrufen (Art. 7 Abs. 3 DSGVO). Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen.",
    right_of_rectification: "Recht auf Berichtigung unrichtiger oder unvollständiger personenbezogener Daten",
    right_of_rectification_addition: "(Art. 16 DSGVO)",
    right_of_restriction: "Recht auf Einschränkung der Verarbeitung personenbezogener Daten",
    right_of_restriction_addition: "(Art. 18 DSGVO)",
    save_changes: "Änderungen speichern",
    save_email: "Email speichern",
    save_password: "Passwort speichern",
    save_settings: "Einstellungen speichern",
    save_user_details: "Nutzerdaten speichern",
    seconds: "Sekunde(n)",
    select_birthdate: "Geburtstag wählen",
    select_date: "Datum wählen",
    select_emotion: "Emotion wählen",
    select_emotion_intensity: "Intensität der Emotion wählen",
    select_gender: "Geschlecht wählen",
    select_language: "Sprache wählen",
    select_university: "Universität wählen",
    selected_emotion: "Emotion gewählt",
    selected_intensity: "Gewählte Intensität",
    show_emotional_images: "Emotionale Bilder Anzeigen",
    show_neutral_images: "Neutrale Bilder Anzeigen",
    signup: "Account erstellen",
    skipped_unsupported_emotion: "Folgende Emotion wird nicht unterstütz",
    social_networks: "6. Soziale Netzwerke und YouTube",
    social_networks_paragraph_1: "Die Universität Ulm verfügt auch über einen Webauftritt in den Sozialen Netzwerken Facebook, Instagram und Twitter. Des Weiteren können Sie verschiedene Videos der Universität Ulm auf YouTube ansehen. Diese Auftritte lassen sich nur via Links aufrufen. Auf eine Einbindung über Social Plugins haben wir bewusst verzichtet, um einen direkten Datenaustausch zu verhindern.",
    start_test: "Test Starten",
    start_test_phase: "Begin Testphase",
    stay_logged_in: "Login speichern",
    subject_id: "Versuchsteilnehmer",
    submit_answer: "Übertragen",
    submit_registration: "Registrierung absenden",
    success: "Erfolg",
    success_animations: "Erfolgsanimation",
    success_rate: "Erfolgsrate (in %)",
    success_rate_hint: "Prozentualer Anteil der richtig gewählten Emotionen um den Test erfolgreich zu bestehen.",
    surprise: "Überrascht",
    take_the_test: "Weiter zum Test",
    terms_of_service: "AGB",
    test: "Test",
    test_assignment: "Testzuweisung",
    test_copy: "Kopieren test",
    test_date: "Datum",
    test_description: "Beschreibung",
    test_description_hint: "Beschreibung des Tests",
    test_doesnt_have_images: "Keine Bilder gefunden",
    test_dosnt_have_images: "Keine Bilder gefunden",
    test_edit: "Test bearbeiten",
    test_finished_at: "Test beendet",
    test_pdf_header: "Testergebnisse",
    test_settings: "Test Einstellungen",
    test_started_at: "Test gestarted",
    test_title: "Titel",
    test_title_hint: "Titel des Tests",
    test_was_deleted: "Test gelöscht",
    testphase: "Tesphase",
    tests: "Verfügbare Tests",
    this_will_replace_current_images: "Dies überschreibt die aktuellen Bilder",
    time_between_images: "Zeit zwischen Bildern",
    time_in_seconds_hint: "Zeit in Sekunden",
    time_to_select_result: "Zeit um ein Ergebnis einzugeben",
    tls_encryption: "5. TLS-Verschlüsselung",
    tls_encryption_paragraph_1: "Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z.B. TLS) über HTTPS.",
    to_my_account: "Mein Profil",
    tos_and_privacy_accepted: "Hiermit akzeptiere ich die AGB und Datenschutz bedingungen",
    unallowed_file_extension: "Dateityp nicht erlaubt",
    unequal: "Ungleich",
    university_ulm_country: "Deutschland",
    university_ulm_name: "Universität Ulm",
    university_ulm_plz_location: "89069 Ulm",
    update_account_information: "Account Informationen bearbeiten",
    upload: "Hochladen",
    upload_new_image_zip: "Gezipte Bilddatein hochladen",
    use_level: "Levels verwenden",
    use_levels: "Levels verwenden",
    user_activated: "Benutzer aktiv",
    user_cant_be_created: "Nutzer konnte nicht erstellt werden",
    user_email_not_unique: "Email bereits verwendet",
    user_email_updated: "Email aktualisiert",
    user_id: "Nutzerid",
    user_information_updated: "Benutzerdaten aktualisiert",
    user_lock_at: "Nutzer wird gesperrt am",
    user_locked: "Benutzer gesperrt",
    user_locked_by: "Gesperrt durch",
    user_password_change_success: "Passwort geändert",
    user_project: "Benutzerprojekt",
    user_save_changes: "Änderungen speichern",
    user_was_created: "Benutzer wurde erstellt",
    user_was_deleted: "Nutzer wurde gelöscht",
    user_was_not_created: "Nutzer wurde nicht erstellt",
    username: "Nutzername",
    username_hint: "Sichtbar für andere Studenten, Dozenten und Professoren",
    username_must_be_valid: "Nutzername ungültig",
    users_refreshed: "Nutzer aktuallisiert",
    ust_number: "Umsatzsteueridentifikationsnummer",
    value_choosen: "Wert gewählt",
    verification_mail__send: "Eine Email zur Verifikations wurde versendet",
    verification_mail_send: "Bestätigungsemail wurde versendet.",
    view_user_test_overview: "Testergebnisse anzeigen",
    warning: "Warnung",
    warning_this_will_count_as_attempt: "Achtung! Mit dem bestätigen beginnt der Versuch",
    webmaster: "Webmaster",
    welcome: "Willkommen",
    welcome_mail_subject: "Fragen oder Probleme",
    welcome_questions_or_issues: "Bei Fragen oder Problemen können Sie sich hier melden",
    welcome_to_the_feeltest: "Wilkommen beim Feeltest",
    welcome_to_the_feeltest_sub: "",
    when_should_the_user_be_deleted: "Benutzer löschen am",
    wrong_emotion: "Falsche Emotion",
    yes: "Ja",
    you_are_about_to_cancel_running_test: "Sie sind dabei den laufenden Test abzubrechen. Dies wird als nicht bestandener Versuch gezählt!",
    you_didnt_pass_the_test: "Test nicht bestanden",
    you_passed_the_test: "Test bestanden",
    your_about_to_lock: "Sie sind dabei",
    your_about_to_lock_2: "zu sperren",
    your_result: "Ihr Ergebnis",
    },"en": {
        access_forbidden: "Access forbidden",
    account: "Account",
    account_is_locked: "Account is locked.",
    accountstart_test: "Start test",
    actions: "Actions",
    activated: "Activated",
    admin_client: "Clientadministration",
    admin_client_desc: "Client overview, Lock clients, Edit clients ...",
    admin_overview: "Admin overview",
    admin_overview_desc: "Admin Project Overview",
    admin_page: "Admin Page",
    admin_projects: "Projectadministration",
    admin_test_desc: "Administration of Tests",
    admin_tests: "Testadministration",
    admin_users: "Useradministration",
    admin_users_desc: "User overview, Lock users",
    allow_test_phase: "Start with testphase",
    allowed_attempts: "Allowed Attempts",
    allowed_attempts_hint: "Number of repetitions of the test",
    almost_done: "almost done",
    an_emotional_image_didnt_provide_any_data: "An emotional image didn't provide any information",
    anger: "Anger",
    are_you_sure: "Are you sure?",
    are_you_sure_to_reset_password: "Reset password",
    attempt: "Attempt",
    attempts: "Attempts",
    available_project_tests: "Available Project Tests",
    available_user_roles: "Available roles",
    avg_difference: "avg difference",
    avg_input_passed: "Correctly identified in",
    avg_passed_images: "Average passed images",
    avg_time_to_answer: "avg answertime",
    back_to_test_overview: "Back to testoverview",
    bank_bic: "MARKDEF1630",
    bank_details: "Bank details",
    bank_details_name: "Kasse der Universität Ulm",
    bank_iban: "DE72 6300 0000 0063 0015 05",
    bank_name: "Bundesbank Ulm",
    between_image_time: "Time between images",
    bic: "BIC (SWIFT-Code):",
    birthdate: "Birthdate",
    can_only_be_changed_by_admin: "Can only be changed by admin",
    cancel: "Cancel",
    cant_move_uploaded_file: "cant move uploaded file",
    change_email: "Change email",
    change_password: "Change password",
    change_test_settings: "Edit test settings",
    change_username: "Edit username",
    chief_adress: "Address of the Chief Financial Officer",
    chief_name: "Chief Financial Officer Dieter Kaufmann",
    chief_street: "Helmholtzstrasse 16",
    choosen_emotion: "Selected emotion",
    choosen_emotion_intensity: "Selected emotion intensity",
    click_to_preview_emotional_image: "Preview the emotional image",
    click_to_preview_neutral_image: "Preview the neutral image",
    click_to_show_images: "Show images",
    client_create_new: "Create",
    client_email: "Client email",
    client_name: "Client Name",
    client_name_hint: "Name",
    client_save_changes: "save changes",
    close: "close",
    Conception: "Conception, realization and organization",
    Conception_company: "CodenGround AG",
    confirm: "Confirm",
    connection_failed: "Connection failed",
    content: "Content",
    content_footer_hint: "The footer menu of each website provides information on this.",
    content_question_hint: "If you have any questions, please contact the person mentioned in the footer at the named email address or at the address Ulm University, Helmholtzstr. 16, 89081 Ulm, Germany.",
    content_responsibility: "The responsibility for the contents of websites of individual institutions or areas of the University lies with those who created and published those contents.",
    cookies: "2. Cookies",
    cookies_paragraph_1: "In addition to the aforementioned data, session cookies will be stored on your computer when you visit our website (legal basis for the use is art. 6 para. 1 lit. f GDPR). A cookie is a text file that is created when you visit the website and stored on your hard drive. When you open the website again, the browser in which the website is opened sends the received cookie back to the server. This enables our server to analyse certain information. Session cookies are deleted automatically when the browser is closed.",
    cookies_paragraph_2: "It is not possible to use cookies to start programmes or transfer viruses onto a computer. We use cookies exclusively to increase the user-friendliness of our website and analyse user behaviour. Session cookies are required for a range of functions, such as login, Powermail and the web analytics software Google Analytics.",
    cookies_paragraph_3: "You can limit or deactivate the use of all or only certain cookies via the settings in your browser. Your browser’s help function provides instructions for how to do this. Please note that the functionality of this website might be limited when cookies are deactivated.",
    copy: "Copy",
    copyright: "Copyright",
    copyright_text: "All contents published on this website (layouts, texts, pictures, graphics etc.) are subject to copyright. Any use that is not authorised according to copyright law requires the previous explicit written agreement from Ulm University. This particularly applies to copying, editing, translating, saving, processing and playing back contents in databases or other electronic media and systems. Photocopies and downloads of websites for private, academic and non-commercial use are permitted. Ulm University explicitly allows and welcomes citations of documents and websites as well as linking to its website. Ulm University strives to honour copyrights of graphics, audio documents, video sequences and texts and to use either its own or license-free graphics, audio documents, video sequences and texts in all its publications. All brand names and trademarks that are mentioned anywhere in the University's web contents and that may be protected by third parties are subject to the regulations of the respective trademark law and the right of ownership of their registered proprietor. Mentioning alone is no reason to conclude that trademarks are not protected by third-party rights!",
    could_not_assign_role: "Error when assigning the default role",
    create_client: "New client",
    create_new_user: "Create",
    create_project: "create Project",
    create_test: "New Test",
    create_user: "Create user",
    created_at: "Create at",
    current_images: "current images",
    current_level: "Level",
    current_project_tests: "Current Project Tests",
    current_user_roles: "Current roles",
    data_protection_officer: "I. Name and address of the legally accountable institution and contact of data protection officer",
    data_protection_officer_fax: "+49 (0)731/50-22038",
    data_protection_officer_law: "Legally accountable institution as per art. 4 para. 7 GDPR (General Data Protection Regulation) is",
    data_protection_officer_paragraph_1: "Ulm University is a corporation under public law. It is legally represented by its President Prof. Dr.-Ing. Michael Weber (praesident(at)uni-ulm.de) or its Chief Financial Officer Dieter Kaufmann (kanzler(at)uni-ulm.de)",
    data_protection_officer_paragraph_2: "If you have questions concerning data protection, please send an email to the data protection officer to dsb(at)uni-ulm.de or a letter with the additional specification \"Datenschutzbeauftragte\" to the above-mentioned address.",
    data_protection_officer_phone: "+49 (0)731/50-10",
    db_save_error: "Failed to save changes",
    db_save_success: "Successfuly saved",
    db_save_successful: "Saved to Database",
    delete_client: "delete client",
    delete_user: "Delete user",
    delete_user_are_you_sure: "Are you sure you want to delete this user?",
    description: "Description",
    difference: "Difference",
    disclaimer: "Disclaimer",
    disclaimer_extern: "Exclusion of liability for the University’s own contents",
    disclaimer_extern_text: "According to general law, Ulm University is responsible for its own contents that it provides on the internet. It is important to distinguish between the University\’s own contents and references or hyperlinks to the contents of other providers (\"third-party contents\"). These third-party contents are neither created by Ulm University, nor does Ulm University have any influence on contents of third-party websites. The contents of third-party websites to which Ulm University references via links do not reflect the opinion of Ulm University and serve merely to provide additional information and context. The University does not take ownership of or assume liability for these linked third-party contents. This applies to all links and references within the University\'s internet presence as well as to third-party entries in guest books, discussion forums and mailing lists that were established by Ulm University. Liability for illegal, erroneous and incomplete contents and, above all, for damages resulting from the use or disregard of information presented in this manner, shall lie solely with the provider of the page to which any of these links refer and not with Ulm University.",
    disclaimer_own: "Exclusion of liability for the University’s own contents",
    disclaimer_own_text: "Ulm University makes every effort to ensure that the information and data contained on its website is correct. However, we can accept no liability or guarantee that the information and data provided is up-to-date, correct and complete. Ulm University particularly assumes no liability for potential damages or consequences resulting from direct or indirect use of the provided contents.",
    disgust: "Disgust",
    diverse: "Divers",
    edit_permissions: "Edit permissions",
    edit_permissions_of: "Edit permissions of",
    edit_tests_of: "Edit Tests Of",
    edited: "Edit",
    email: "Email",
    email_communication: "4. Email communication",
    email_communication_paragraph_1: "On our website, you usually have the option to get in touch with contact persons of Ulm University via the provided email addresses. In this case, the personal data transmitted by you are being stored. Ulm University uses these data exclusively for the processing of the conversation and deletes them once the purpose has been fulfilled. Legal basis is your agreement as per art. 6 para. 1 lit. a GDPR.",
    email_communication_paragraph_2: "You have the right to object to the use of your data any time with effect for the future.",
    email_hint: "enter Email",
    email_must_be_valid: "Invalid email",
    email_template_not_found: "Emailtemplate not found",
    emotion: "Emotion",
    emotion_from_image: "Emotion from image",
    emotion_intensity: "Emotion intensity",
    emotional_image: "Emotional image",
    emotional_image_time: "Emotional image display time",
    emotional_images_time: "Emotional image display time",
    emotional_images_will_be_shown_for: "Emotional images will be shown for",
    enter_user_information: "Enter userinformation",
    equal: "Equal",
    error: "Error",
    error_when_saving: "An error occured when saving",
    explanation: "Introduction",
    export_csv: "Export CSV",
    export_excel: "Export Excel",
    fail_animations: "Fail Animations",
    failed_to_update_tests_images_relations: "Failed to update Test - Image relations",
    fax: "Telefax",
    fear: "Fear",
    feeltest_title: "University Ulm - Feeltest",
    female: "Female",
    female_male: "Female and male spelling",
    female_male_text: "For reasons of better readability, the masculine spelling was generally used. At this point, we expressly point out that both the male and the female spelling are meant for the corresponding contributions.",
    file_name: "Filename",
    filter_entries: "Filter entries",
    filter_tests: "Filter tests",
    finished: "Finished",
    for: "for",
    force_settings: "Force settings",
    force_settings_false: "Testsettings open",
    force_settings_true: "Testsettings locked",
    gender: "Gender",
    gender_must_be_valid: "Invalid gender",
    general_data_processing: "II. General information on data processing",
    general_data_processing_collecting: "1. Collection of personal data as part of providing access to the website and storage of server log files",
    general_data_processing_collecting_text: "When you use our website for purely informative reasons, we collect the personal data that your browser transfers to our server and which are technically necessary in order to present our website to you and ensure stability and safety (legal basis is art. 6 para. 1 s. 1 GDPR)",
    general_data_processing_server_log: "We store the above-mentioned data in so-called server log files in order to ensure the website’s functionality. This also serves to optimise the website and safeguard the security of our IT systems. The stored IP addresses are made anonymous each night by way of shortening to the last two domain parts. User profiles are not created. The shortened IP addresses and all other data are statistically evaluated once a month and deleted after 35 days. The collection of data for the provision of access to the website and the storage in server log files is a necessary requirement for the operation of the website. There is no option for the user to object.",
    general_data_subject: "III. Your rights as \"data subject\"",
    general_data_subject_paragraph_1: "You have the following rights with regards to personal data concerning you",
    general_data_subject_paragraph_2: "You furthermore have the right to file a complaint with the supervisory authority for data protection regarding the processing of your personal data by us. Responsible supervisory authority is the State Commissioner for Data Protection and Freedom of Information Baden-Württemberg.",
    give_feedback_after_each_image: "Feedback after each image",
    google_analytics: "3. Google Analytics",
    google_analytics_paragraph_1: "This website uses Google Analytics, a web analytics service of Google Inc. (\"Google\"). Google Analytics uses so-called cookies, which are text files that get stored on your computer in order to analyse your usage of the website. The information that the cookie gathers regarding your usage of this website is usually transferred to and stored on a Google server in the USA.",
    google_analytics_paragraph_2: "This website has IP anonymisation activated. Within the member states of the European Union or any other countries that are contracting party to the Agreement on the European Economic Area, Google shortens your IP address prior to the transfer. Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there.",
    google_analytics_paragraph_3: "Google uses this information by order of the website operator to analyse your usage of this website, compile reports on the website activities and provide further services in relation to website usage and internet usage to the website operator. The IP address transferred by your browser as part of Google Analytics is not consolidated with other data from Google.",
    grief: "Grief",
    iban: "IBAN:",
    image: "Image",
    image_upload: "Image upload",
    imprint: "Imprint",
    inactive: "Inactive",
    info: "Info",
    intensity: "Intensity",
    intensity_from_image: "Intensity specification",
    invalid_login_credentials: "Invalid login credentials",
    it: "IT",
    it_responsible: "If you have technical questions or problems, please send an email to our",
    joy: "Joy",
    just_one_click_left: "Just one click",
    lang: "Language",
    language: "Language",
    language_dosnt_exist: "Invalid language",
    leave_empty_to_not_change: "Leave empty to not change",
    link_already_used: "This link was already used.",
    link_dosnt_exist: "link doesnt exist or is invalid",
    lock_client: "lock client",
    lock_user: "Lock user",
    lock_user_at: "Set expiry date",
    lock_user_canceled: "Lock user was canceled",
    locked: "Locked",
    login: "Login",
    login_attempts: "Login attempts",
    login_failed: "Login Failed",
    login_is_invalid: "Invalid login",
    login_successful: "Login successful",
    logout: "Logout",
    mail_cant_be_send: "Mail cant be send",
    male: "Male",
    max_offset: "max offset",
    max_offset_hint: "max offset",
    max_selection_time: "Time to select an result",
    more: "more",
    must_accept_tos_and_privacy: "TOS and Privacy Policy must be accepted",
    must_be_logged_in: "You have to be logged in",
    my_account: "My Account",
    name: "Name",
    neutral_image: "Neutral image",
    neutral_image_time: "Neutral image display time",
    neutral_images_will_be_shown_for: "Neutral images will be shown for",
    next: "Next",
    no: "No",
    no_data_found: "No data found",
    no_file: "No File",
    no_file_selected: "You have not selected a file.",
    no_images_yet: "no images yet",
    no_test_round_after_attempt: "no test round after attempt",
    no_user_with_that_email_found: "No user with such email found",
    no_zip: "No ZIP-File",
    not_all_pictures_are_pairs: "Not all pictures are pairs.",
    not_selected: "no selection",
    now_go_to_next_level: "Now start the next level!",
    offset: "Offset",
    okey: "OK",
    open_app: "Would you like to open the app?",
    other_bank_hint: "(payments from overseas / study fees)",
    other_banks: "Further bank accounts",
    passed: "Passed",
    password: "Password",
    password_forgotten: "password forgotten",
    password_must_be_valid: "Password invalid",
    password_repeat: "Repeat password",
    password2: "Repeat password",
    pdf: "PDF",
    personal_data: "Personal information",
    personal_data_desc: "Update personal information",
    phone: "Phone",
    play_sound_on_start: "Play startsound",
    please_note: "Please Note",
    precision_offset: "Maximum deviation",
    precision_offset_hint: "Maximum permitted deviation in emotion intensity so that a user's selection is recognized as correct.",
    preparing_test: "preparing test",
    president_adress: "Address of the President",
    president_name: "President Prof. Dr.-Ing. Michael Weber",
    president_street: "Helmholtzstrasse 16",
    previous: "Back",
    privacy_policy: "Privacy policy",
    privacy_policy_changes: "IV. Changes to our Privacy Policy",
    privacy_policy_changes_paragraph_1: "To continuously keep our Privacy Policy in line with the current legal requirements, we reserve the right to amend our Privacy Policy as needed. To your own protection we recommend that you read the Privacy Policy regularly. By using our website, you accept our Privacy Policy and its updates.",
    privacy_policy_description: "This page provides information on the collection of personal data when you visit our website. Personal data is any information concerning you personally, e.g. name, home address, email address, user behaviour.",
    privacy_policy_description_addition: "This Privacy Policy is available in German and English language. In the event of discrepancies or contradictions between the German and the English version, the German version shall prevail.",
    project: "Project",
    project_description: "Project Description",
    project_description_hint: "Project Description Hint",
    project_id: "Project id",
    project_save_changes: "Save Project Changes",
    project_title: "project Title",
    project_title_hint: "Project Title Hint",
    projects: "Projectadministration",
    publisher: "Publisher",
    publisher_address_fax: "+49 (0)731/50-22038",
    publisher_address_phone: "+49 (0)731/50-10",
    publisher_address_uSt: "DE173703203",
    ready_to_start_the_test: "Ready to start the test?",
    register: "Create Account",
    regulation_ministry: "Ministry of Science, Research and the Arts Baden-Württemberg",
    regulation_ministry_location: "70173 Stuttgart",
    regulation_ministry_street: "Königstraße 46",
    regulation_responsible: "Responsible regulating authority",
    remove_timer: "Remove expiry date",
    representation: "Representation",
    representation_text: "Ulm University is a public body and is represented by its President, Prof. Dr.-Ing. Michael Weber, or its Chief Financial Officer, Dieter Kaufmann.",
    request_access_state: "Access status / HTTP status code",
    request_browser: "Browser type",
    request_browser_language: "Language and version of the browser software",
    request_content: "Content of request (access address of the specific web page)",
    request_date_and_time: "Date and time of request",
    request_ip_adress: "IP adress",
    request_os: "Operating system and surface software",
    request_referer_address: "\"Referrer\" address, if applicable",
    request_timezone: "Time zone difference to Greenwich Mean Time (GMT)",
    request_volume: "Volume of transferred data (bytes)",
    reset_login: "Reset Login Attempts",
    reset_password: "Reset Password",
    reset_settings: "Reset settings",
    reset_test_settings_to_default: "Are you sure you want to reset your Test settings to default?",
    return_to_available_tests: "Available tests",
    return_to_project_overview_without_saving: "Return To Project Overview Without Saving",
    return_to_test_overview_without_saving: "Changes will be lost if you leave now. Leave anyway?",
    right_of_erasure: "Right to erasure of stored personal data",
    right_of_erasure_addition: "so long as their processing is not necessary for the purpose of exercising the right to freedom of speech and information, for the fulfilment of legal obligations, for reasons of public interest or the establishment, exercise or defence of legal claims (art. 17 GDPR)",
    right_of_information: "Right to information and access",
    right_of_information_addition: "to your personal data that are stored by us (art. 15 GDPR)",
    right_of_portability: "Right to data portability",
    right_of_portability_addition: "(art. 20 GDPR)",
    right_of_processing: "Right to object to processing",
    right_of_processing_addition: "that serves our legitimate or a public interest, unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the establishment, exercise or defence of legal claims (art. 21 GDPR); you have the right to withdraw your consent to the processing and use of your personal data at any time with effect for the future (art. 7 para. 3 GDPR). In consequence, we must discontinue the data processing which was based on this consent in the future.",
    right_of_rectification: "Right to rectification of inaccurate or incomplete personal data",
    right_of_rectification_addition: "(art. 16 GDPR)",
    right_of_restriction: "Right to restriction of processing of personal data",
    right_of_restriction_addition: "(art. 18 GDPR)",
    save_changes: "Save changes",
    save_email: "Save email",
    save_password: "Save password",
    save_settings: "Save settings",
    save_user_details: "Save userdata",
    seconds: "second(s)",
    select_birthdate: "Select birthdate",
    select_date: "Select date",
    select_emotion: "Select emotion",
    select_emotion_intensity: "Select the intensity of the emotion",
    select_gender: "Select gender",
    select_language: "select language",
    select_university: "Select university",
    selected_emotion: "Selected emotion",
    selected_intensity: "Choosen intensity",
    show_emotional_images: "Show emotional images",
    show_neutral_images: "Show neutral images",
    signup: "Create Account",
    skipped_unsupported_emotion: "The following emotion is not supported",
    social_networks: "6. Social networks and YouTube",
    social_networks_paragraph_1: "Ulm University maintains web presences in the social networks Facebook, Instagram and Twitter. You can also watch videos of Ulm University on YouTube. These presences can only be opened via links. We deliberately refrain from embedding social plug-ins in order to prevent direct data exchange.",
    start_test: "Stat the test",
    start_test_phase: "start test phase",
    stay_logged_in: "Stay logged in",
    subject_id: "Participant",
    submit_answer: "submit",
    submit_registration: "Submit registration",
    success: "Success",
    success_animations: "success Animations",
    success_rate: "Success rate (in %)",
    success_rate_hint: "Percentage of correctly chosen emotions to successfully pass the test. ",
    surprise: "Surprise",
    take_the_test: "Continue to the test",
    terms_of_service: "TOS",
    test: "Test",
    test_assignment: "Test assignment",
    test_copy: "Test Copy",
    test_date: "Date",
    test_description: "Description",
    test_description_hint: "Description of test",
    test_doesnt_have_images: "no images found",
    test_dosnt_have_images: "No images found",
    test_edit: "Test Edit",
    test_finished_at: "Test finished",
    test_pdf_header: "Testresults",
    test_settings: "Test settings",
    test_started_at: "Test started",
    test_title: "Title",
    test_title_hint: "Title of test",
    test_was_deleted: "Test deleted",
    testphase: "Tesphase",
    tests: "Available Tests",
    this_will_replace_current_images: "This will replace the current images",
    time_between_images: "Time between images",
    time_in_seconds_hint: "Time in seconds",
    time_to_select_result: "Time to enter the result",
    tls_encryption: "5. TLS encryption",
    tls_encryption_paragraph_1: "We use state-of-the-art encryption methods (e.g. TLS) via HTTPS in order to protect the security of your data during transfer.",
    to_my_account: "My Profile",
    tos_and_privacy_accepted: "I accept the TOS and privacy policy conditions",
    unallowed_file_extension: "Filetpye not allowed",
    unequal: "Unequal",
    university_ulm_country: "Germany",
    university_ulm_name: "Ulm University",
    university_ulm_plz_location: "89069 Ulm",
    update_account_information: "Update account information",
    upload: "Upload",
    upload_new_image_zip: "Upload ZIP with images",
    use_level: "Use Levels",
    use_levels: "Use Levels",
    user_activated: "user Activated",
    user_cant_be_created: "User couldn't be created",
    user_email_not_unique: "Email is already in use",
    user_email_updated: "Email updated",
    user_id: "Userid",
    user_information_updated: "Userinformation updated",
    user_lock_at: "User will be locked at",
    user_locked: "User locked",
    user_locked_by: "Locked by",
    user_password_change_success: "Password changed",
    user_project: "User Project",
    user_save_changes: "Save changes",
    user_was_created: "User was created",
    user_was_deleted: "User was deleted",
    user_was_not_created: "User wasn't created",
    username: "Username",
    username_hint: "Visible to other Students, lecturers and professors",
    username_must_be_valid: "Invalid username",
    users_refreshed: "Users refreshed",
    ust_number: "Value added tax identification number",
    value_choosen: "Value choosen",
    verification_mail__send: "A email for verification was sent.",
    verification_mail_send: "Verification mail was send.",
    view_user_test_overview: "Review Testresults",
    warning: "Warning",
    warning_this_will_count_as_attempt: "Warning! With confirming the attempt will begin",
    webmaster: "webmaster",
    welcome: "Welcome",
    welcome_mail_subject: "Questions or Issues",
    welcome_questions_or_issues: "In case of questions or issues we can be contaced here",
    welcome_to_the_feeltest: "Welcome to the Feeltest",
    welcome_to_the_feeltest_sub: "",
    when_should_the_user_be_deleted: "delete user at",
    wrong_emotion: "Wrong Emotion",
    yes: "Yes",
    you_are_about_to_cancel_running_test: "Your about to cancel the running test. This will be counted as a failed attempt!",
    you_didnt_pass_the_test: "Test not passed",
    you_passed_the_test: "Test passed",
    your_about_to_lock: "Your about to lock",
    your_about_to_lock_2: " ",
    your_result: "Your result",
    },
};

    export const languages = [
    
    {
        name: "Deutsch",
        short: "de",
    },
    
    {
        name: "English",
        short: "en",
    },
    
    ];
    