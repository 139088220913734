import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageService} from '../language.service';
import {DialogService} from '../dialog.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

interface ActivateServerResponse {
  success: boolean;
  error: boolean;
  data: string;
  info: string[];
  warning: string[];
  errors: string[];
}

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  linkId: string | null = null;
  type: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private httpClient: HttpClient,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    // console.log(this.linkId, this.type)
    this.linkId = this.route.snapshot.params.linkId;
    this.type = this.route.snapshot.params.type;

    this.sendRequest();
  }

  private sendRequest() {
    switch (this.type) {

      case 'user':
        break;

      default:
    }

    this.activate();
  }

  private activate() {
    const body = new FormData();

    body.append('func', 'ACTIVATE');
    body.append('linkId', this.linkId);

    this.httpClient.post<ActivateServerResponse>(environment.ApiLocation, body).subscribe(
      response => {
        if (response.success) {
          this.dialogService.openSnackBar(response.data);
          this.router.navigate(['']);
        } else {
          this.dialogService.openDialog('error', response.errors);
          this.router.navigate(['']);

        }
      }
    );
  }

}
