import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../user/user.service';
import {DialogService} from '../shared/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permissionString = 'can_view' + state.url.split('/').join('_');
    const index = permissionString.indexOf('edit');
    console.log(permissionString);
    const index2 = permissionString.indexOf('details');

    const project_details = permissionString.indexOf('project_details');


    if (index > -1) {
      permissionString = permissionString.slice(0, index + 4);
    }
   if(index2 > -1)
    {
      permissionString = permissionString.slice(0, index2 + 7);
    }


    return new Observable<boolean | UrlTree>(
      subscriber => {
        this.userService.afterLogin.subscribe(
          afterLogin => {
            // console.log('PROTECTED_ROUTE_REQUIRES', permissionString);

            if (afterLogin) {

              if (this.userService.hasRightTo(permissionString)) {
                // console.log('ACCEPTED');
                subscriber.next(true);
              } else {
                // console.log('REJECTED');
                this.dialogService.openSnackBar('access_forbidden');
                subscriber.next(this.router.createUrlTree(['']));
              }

            } else {

              this.userService.onInitialUser.subscribe(
                user => {
                  if (this.userService.hasRightTo(permissionString)) {
                     console.log('ACCEPTED');
                    subscriber.next(true);
                  } else {
                     console.log('REJECTED');
                    this.dialogService.openSnackBar('access_forbidden');
                    subscriber.next(this.router.createUrlTree(['']));
                  }
                }
              );
            }
          }
        );
      }
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permissionString = 'can_view' + state.url.split('/').join('_');
    const index = permissionString.indexOf('edit');
    const index2 = permissionString.indexOf('details');
    console.log(permissionString);
    const project_details = permissionString.indexOf('project_details');
    const projectsDrag = permissionString.indexOf('projects_drag');
    const userproject = permissionString.indexOf('userproject');
    console.log(projectsDrag);
    if (index > -1) {
      permissionString = permissionString.slice(0, index + 4);
    }
    if (projectsDrag > -1) {
      console.log(projectsDrag);
      permissionString = permissionString.slice(0, projectsDrag + 13);
    }
    if(index2 > -1)
    {
      permissionString = permissionString.slice(0, index2 + 7);
    }

    if(userproject > -1)
    {
      permissionString = permissionString.slice(0, userproject + 11);
    }
    return new Observable<boolean | UrlTree>(
      subscriber => {
        this.userService.afterLogin.subscribe(
          afterLogin => {
            // console.log('PROTECTED_ROUTE_REQUIRES', permissionString);

            if (afterLogin) {

              if (this.userService.hasRightTo(permissionString)) {
                 console.log('ACCEPTED');
                subscriber.next(true);
              } else {
                 console.log('REJECTED'+permissionString);
                this.dialogService.openSnackBar('access_forbidden');
                subscriber.next(this.router.createUrlTree(['']));
              }

            } else {

              this.userService.onInitialUser.subscribe(
                user => {
                  if (this.userService.hasRightTo(permissionString)) {
                     console.log('ACCEPTED');
                    subscriber.next(true);
                  } else {
                     console.log('REJECTED');
                    this.dialogService.openSnackBar('access_forbidden');
                    subscriber.next(this.router.createUrlTree(['']));
                  }
                }
              );
            }
          }
        );
      }
    );
  }
}
