import {UserSettings} from "./account/user-settings";

export class UserModel {
  constructor(
    public username: string,
    public email: string,
    public lang: string,
    public gender: string,
    public birthdate: string,
    public permission: string[],
    public token: string,
    public userSettings: UserSettings | false,
    public tokenExpiration: number,
  ) {}
}

export interface UserInterface {
  username: string;
  email: string;
  lang: string;
  gender: string;
  birthdate: string;
  rights: string[];
  token: string;
  userSettings: UserSettings | false;
  tokenExpiration: number;
  tokenValidFor: number;
}


