import {User} from '../../users/user';
import { Component, OnInit, ViewChild  } from '@angular/core';
import {AdminService} from '../../admin.service';
import {DialogService} from '../../../shared/dialog.service';
import {LanguageService} from '../../../shared/language.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Project} from '../../projects/project';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Language} from '../../../shared/language.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {UserService} from '../../../user/user.service';

@Component({
  selector: 'app-user-project',
  templateUrl: './user-project.component.html',
  styleUrls: ['./user-project.component.scss']
})

/*
export class UserProjectComponent implements OnInit {
  public editedProject;
  languages: Language[];
  userProject = [];
  AssignProject = [];

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }
  onProjectsChange = new BehaviorSubject<Project[]>(null);
  projects: Project[] = null;
  /!*displayedColumns: string[] = [ 'project_id',  'name'];*!/
  dataSource = new MatTableDataSource(this.projects);

  expandedProject: string | null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  /!* ngOnInit() {
     if (true) {
       this.adminService.fetchProjects().subscribe();
     }

     this.adminService.onProjectsChange.subscribe(
       (projects) => {
         if (projects !== null) {
           this.projects = projects;
           this.dataSource = new MatTableDataSource(this.projects);
           this.dataSource.paginator = this.paginator;
           this.dataSource.sort = this.sort;
         }
       }
     );
   }*!/

  ngOnInit() {
    const userId = this.route.snapshot.params.username;
    console.log(userId);
    this.route.data.subscribe();
    this.editedProject = this.adminService.getUserById(userId)
    this.adminService.fetchUserProject(this.editedProject.project_id).subscribe(
      result => {
        if (result.success) {
          this.userProject = result.data.User;
          // console.log(this.projectTests);
          this.AssignProject = result.data.Project;
        }
      }
    );
  }

  test() {
    this.router.navigate([
      '/admin',
      'projects',
      'details/:userid'
    ]);

  }


  onExpandedProjectChange(expandedProject: Project) {
    this.expandedProject = expandedProject.project_id;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  onDeleteProject(projectId: string) {
    this.adminService.deleteProject(projectId).subscribe(
      response => {
        if (response.success) {
          this.dialogService.openSnackBar(response. data);
        } else {
          this.dialogService.openDialog('error', response.errors);
        }
      }
    );
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  onSubmit() {
    let assignedProjects = [];
    for (const test of this.userProject) {
      assignedProjects.push(test.test_id);
    }

    this.adminService.updateUserProjectAdmin(this.editedProject.project_id, assignedProjects).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate (['/admin', 'users']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }
}

*/
export class UserProjectComponent implements OnInit {

  public user;
  languages: Language[];
  projects = [];
  availableProjects = [];
  projectId = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private adminService: AdminService,
    private userService: UserService
  ) { }



  ngOnInit() {
    const userId = this.route.snapshot.params.userid;
    this.route.data.subscribe();
    this.user = this.adminService.getUserById(userId);
    this.adminService.fetchUserProject(this.user.user_id).subscribe(
      result => {
        if (result.success) {
          this.projects = result.data.projects;
          this.availableProjects = result.data.availableProjects;
        }
      }
    );
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onSubmit() {
    let assignedProjects = [];
    for (const project of this.projects) {
      assignedProjects.push(project.project_id);
    }
    this.adminService.updateUserProjectAdmin(this.user.user_id, assignedProjects).subscribe(
      (result) => {
        if (result.success) {
          this.dialogService.openSnackBar(result.data[0]);
          this.router.navigate (['/admin', 'users']);
        } else {
          this.dialogService.openDialog('error', result.errors);
        }
      }
    );
  }

}
