import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageService} from '../../shared/language.service';
import {TestService} from '../../admin/tests/test.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../shared/dialog.service';
import {UserService} from '../../user/user.service';

@Component({
  selector: 'app-test-preview',
  templateUrl: './test-preview.component.html',
  styleUrls: ['./test-preview.component.scss']
})
export class TestPreviewComponent implements OnInit {

  public testSettingsForm: FormGroup;
  public test: Test;
  private reloadTranslation = false;
  public loading = false;

  public ShowNeutralImages: any;
  public PlaySoundOnStart: any;
  public NeutralImageTime: any;
  public MaxSelectionTime: any;
  public EmotionalImageTime: any;
  public BetweenImageTime: any;
  public SubjectID: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public languageService: LanguageService,
    private testService: TestService,
    private userService: UserService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    const testId = this.route.snapshot.params.testId;
    this.route.data.subscribe(
      result => {
        this.test = this.testService.getTestById(testId);
        if (this.test !== null) {
          this.initForm();
        } else {
          this.dialogService.openSnackBar('test_not_found');
          this.router.navigate(['/tests']);
        }
      }
    );
    this.languageService.onLanguageChange.subscribe(
      () => {
        this.testService.fetchTestOverview().subscribe();
      }
    );
    this.testService.onTestsChange.subscribe(
      () => {
        const newTest = this.testService.getTestById(testId);
        this.test.test_title = newTest.test_title;
        this.test.test_description = newTest.test_description;
      }
    );

  }
  initForm() {
    // if this.test.force_settings == 0 then use this.userService.user.userSettings

    this.ShowNeutralImages = this.test.show_neutral_images;
    this.PlaySoundOnStart = this.test.play_sound_on_start;
    this.NeutralImageTime = this.test.neutral_image_time;
    this.MaxSelectionTime = this.test.max_selection_time;
    this.EmotionalImageTime = this.test.emotional_image_time;
    this.BetweenImageTime = this.test.between_image_time;
    this.SubjectID = this.test.subject_id;

    // User UserSettings if test is force_settings = 0
    if (this.test.force_settings === 0) {
      if (this.userService.user.userSettings !== false) {
          this.ShowNeutralImages = this.userService.user.userSettings.show_neutral_images;
          this.PlaySoundOnStart = this.userService.user.userSettings.play_sound_on_start;
          this.NeutralImageTime = this.userService.user.userSettings.neutral_image_time;
          this.MaxSelectionTime = this.userService.user.userSettings.max_selection_time;
          this.EmotionalImageTime = this.userService.user.userSettings.emotional_image_time;
          this.BetweenImageTime = this.userService.user.userSettings.between_image_time;
      }
    }
    this.testSettingsForm = new FormGroup({
      settings: new FormGroup({
        subject_id: new FormControl({value: this.test.subject_id, disabled: false}),
        allowed_attempts: new FormControl({value: this.test.allowed_attempts, disabled: true}),
        show_neutral_images: new FormControl({value: this.ShowNeutralImages, disabled: this.test.force_settings === 1}),
        neutral_image_time: new FormControl({value: this.NeutralImageTime, disabled: this.test.force_settings === 1}),
        show_emotional_images: new FormControl({value: true, disabled: true}),
        emotional_image_time: new FormControl({value: this.EmotionalImageTime, disabled: this.test.force_settings === 1}),
        between_image_time: new FormControl({value: this.BetweenImageTime, disabled: this.test.force_settings === 1}),
        max_selection_time: new FormControl({value: this.MaxSelectionTime, disabled: this.test.force_settings === 1}),
        play_sound_on_start: new FormControl({value: this.PlaySoundOnStart, disabled: this.test.force_settings === 1}),
        allow_testphase: new FormControl({value: this.test.allow_testphase === 1, disabled: this.test.allow_testphase !== 1}),
      })
    });

  }

  onStartTest(test: Test) {
    this.dialogService.openDialog(
      'confirm',
      ['warning_this_will_count_as_attempt', 'ready_to_start_the_test'], false, true
    ).afterClosed().subscribe(
      result => {
        if (result) {
          this.runTestSetup(test.test_id, this.testSettingsForm.value.settings.a);
        }
      }, () => {
        this.dialogService.openSnackBar('error_when_loading');
      }
    );
  }

  private runTestSetup(testId: string, isTestPhase: boolean) {
    this.loading = true;
    if (this.test.force_settings === 0) {
      this.test.show_neutral_images = this.testSettingsForm.value.settings.show_neutral_images ? 1 : 0;
      this.test.neutral_image_time = +this.testSettingsForm.value.settings.neutral_image_time;
      this.test.emotional_image_time = +this.testSettingsForm.value.settings.emotional_image_time;
      this.test.between_image_time = +this.testSettingsForm.value.settings.between_image_time;
      this.test.max_selection_time = +this.testSettingsForm.value.settings.max_selection_time;
      this.test.play_sound_on_start = this.testSettingsForm.value.settings.play_sound_on_start ? 1 : 0;
      this.test.allow_testphase = this.testSettingsForm.value.settings.allow_testphase ? 1 : 0;
    }
    this.test.subject_id = this.testSettingsForm.value.settings.subject_id;
    console.log("subject_id: "+ this.test.subject_id);

    this.testService.loadTest(testId, isTestPhase, this.test).subscribe(
      response => {
        if (response.success) {

          this.testService.gradingAndTestPhaseResult = response.data.tests;
          this.testService.activeGradingPhaseTestImages = response.data.tests.gradingPhase;
          this.testService.activeTestPhaseTestImages = response.data.tests.testPhase;
          this.testService.activeTest = this.test;
          this.testService.isTestPhase = isTestPhase;
          this.testService.activeAttempt = response.data.attempt;
          this.loading = false;

          this.router.navigate(['/tests', 'start', testId]).catch(
            () => {
              this.dialogService.openDialog('error', 'e');
            }
          );
        } else {
          this.loading = false;
          this.dialogService.openDialog('error', response.errors);
        }
      }
    );
  }
}
