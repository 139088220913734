/**
 * Admin Service
 *
 * Handles all admin related actions, requests and authentication
 *
 * @author Vincent Menzel
 * @category controller
 * @version 1.0
 */

import {Injectable} from '@angular/core';
import {Page} from '../shared/admin.model';
import {Client} from './client';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UserService} from '../user/user.service';
import {tap} from 'rxjs/operators';
import {
  ClientCreateResponseModel,
  ClientEditResponseModel,
  GetClientsResponseModel,
  GetImagesForTestResponseModel,
  GetTestsResponseModel,
  GetProjectsResponseModel,
  GetUsersResponseModel,
  GetUserTestHistoryResponseModel,
  ServerGetRolesResponseModel,
  ServerGetUserRightsAndRolesResponseModel,
  ServerResponseModel,
  UserDeleteResponse,
  UserEditResponseModel,
  GenerateCSVResponse,
  ProjectDeleteResponse,
  ServerGetUserProjectsAndRolesResponseModel,
  ServerGetUserProjectsResponseModel,
  ServerGetProjectTestsResponseModel,
  ServerGetUserProjectResponseModel,
  ServerupdateUserProjectResponseModel, UserTestOverviewResponseModel, AnimationResponseModel, TestDeleteResponse
} from '../shared/serverresponse.model';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {User} from './users/user';
import {AppService} from '../app.service';

import {UserProjectComponent} from './users/user-project/user-project.component';
import {Project} from './projects/project';
import {TestOfProject} from './test';


interface NewUser {
  username: string;
  email: string;
  lang: string;
  gender: string;
  birthDate: any;
  activated: boolean;
}
interface NewClient {
  client_name: string;
  client_email: string;
  locked: string;
}
interface NewTest {}




class getUserProjectsForAdmin {
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  ///
  //    Current Admin Pages
  ///
  //    New pages need to be added here to show up in the Dropdown and Overview

  adminPages: Page[];
  clients: Client[] = null;
  users: User[] = null;
  tests: Test[] = null;
  projects: Project[] = null;


  onClientsChange = new BehaviorSubject<Client[]>(this.clients);
  onUsersChange = new BehaviorSubject<User[]>(this.users);
  onTestsChange = new BehaviorSubject<Test[]>(this.tests);
  onProjectsChange = new BehaviorSubject<Project[]>(this.projects);
  availableRoles = new BehaviorSubject<string[]>(null);
  private projectService: any;


  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private router: Router,
    private appService: AppService
  ) { }

  public fetchClients() {
    const body = new FormData();

    body.append('func', 'GETCLIENTS');
    body.append('token', this.userService.user.token);

    return this.httpClient.post<GetClientsResponseModel>(environment.ApiLocation, body)
    .pipe(
      tap(
        response => {
          this.clients = response.data.clients;
          this.onClientsChange.next(this.clients);
        }
      )
    );
  }

  public fetchUsers() {
    const body = new FormData();

    body.append('func', 'GETUSERS');
    body.append('token', this.userService.user.token);

    return this.httpClient.post<GetUsersResponseModel>(environment.ApiLocation, body)
    .pipe(
      tap(
        response => {
          this.users = response.data.users;
          this.onUsersChange.next(this.users);
        }
      )
    );
  }




  public fetchTests() {
    const body = new FormData();

    body.append('func', 'GETTESTS');
    body.append('token', this.userService.user.token);

    return this.httpClient.post<GetTestsResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          response => {
            this.tests = response.data.tests;
            this.onTestsChange.next(this.tests);
          }
        )
      );
  }

  public getTestById(test_id: string) {
    return this.tests.find(test => test.test_id == test_id);
  }

  // tslint:disable-next-line:variable-name
  public getProjectById(project_id: string) {
    return this.projects.find(project => project.project_id == project_id);
  }

  public fetchProjects() {
    const body = new FormData();
    body.append('func', 'GETPROJECTS');
    body.append('token', this.userService.user.token);

    return this.httpClient.post<GetProjectsResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          response => {
            console.log(response.data.projects);
            this.projects = response.data.projects;
            this.onProjectsChange.next(this.projects);
          }
        )
      );
  }

  public editClient(editedClient: Client) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'EDITCLIENT');
    body.append('client', JSON.stringify(editedClient));

    return this.httpClient.post<ClientEditResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.toggleClientLockedStatus(editedClient);
            }
          }
        )
      );
  }
  /* public editTest(editedTest: Test) {
     const body = new FormData();

     body.append('token', this.userService.user.token);
     body.append('func', 'EDITTESTS');
     body.append('user', JSON.stringify(editedTest));

     return this.httpClient.post<UserEditResponseModel>(environment.ApiLocation, body)
       .pipe(
         tap(
           (response) => {
             if (response.success) {
               // new ReplaceTest
               this.replaceUser(editedTest);
              this.router.navigate(['/admin', 'tests']);
             }
           }
        )
       );
  }*/

  public newClient(newClient: NewClient, newUser: NewUser) {

    const birthDateTimestamp = newUser.birthDate.getTime() / 1000 + 60 * 60 * 24;

    const body = new FormData();

    //    General Information
    body.append('token', this.userService.user.token);
    body.append('func', 'CREATECLIENT');

    //    Client Information

    body.append('clientName', newClient.client_name);
    body.append('clientEmail', newClient.client_email);
    body.append('clientLocked', newClient.locked ? '1' : '0');

    //    User Information
    body.append('username', newUser.username);
    body.append('email', newUser.email);
    body.append('lang', newUser.lang);
    body.append('gender', newUser.gender);
    body.append('birthDate', birthDateTimestamp.toString());
    body.append('activated', newUser.activated ? '1' : '0');

    //    Make Api Callback
    return this.httpClient.post<ClientCreateResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {


            //    Navigate to Admin Overview if Successful

            if (response.success) {
              this.router.navigate(['/admin']);
            }
          }
        )
      );
  }

  public deleteClient(clientId: string) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'DELETECLIENT');
    body.append('client_id', clientId);

    return this.httpClient.post<ClientEditResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {


            //    If Successful remove Client from Loaded Clients

            if (response.success) {
              this.removeClient(clientId);
            }
          }
        )
      );
  }

  private toggleClientLockedStatus(editedClient: Client) {


    //    Toggles LockedState of Client

    const index = this.clients.findIndex(client => {

      return client.client_id === editedClient.client_id;
    });


    //    Replace Client with new LockedState

    this.clients[index] = editedClient;


    //    Emit updated Clients

    this.onClientsChange.next(this.clients);

  }

  private removeTest(testId: string) {

    this.tests = this.tests.filter(
      test => {
        return test.test_id !== testId;
      }
    );
    this.onTestsChange.next(this.tests);
  }

  private removeProject(projectId: string) {

    this.projects = this.projects.filter(
      project => {
        return project.project_id !== projectId;
      }
    );
    this.onProjectsChange.next(this.projects);
  }

  private removeClient(clientId: string) {

    this.clients = this.clients.filter(
      client => {
        return client.client_id !== clientId;
      }
    );
    this.onClientsChange.next(this.clients);
  }

  public getClientById(clientId: string) {

    return this.clients.find(
      client => {
        return client.client_id === clientId;
      }
    );

  }

 // getTestByIdd(testId: string) {
 //
 //    return this.tests.find(
 //      test => {
 //        return test.test_id === testId;
 //      }
 //    );
 //
 //  }

  ///////////////////////////////////////////
  ///
  ///         User Administration
  ///
  ///////////////////////////////////////////

  public getUserById(userId) {
    return this.users.find(
      user => {
        return user.user_id == userId;
      }
    );
  }

  public editUser(editedUser: User) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'EDITUSER');
    body.append('user', JSON.stringify(editedUser));

    return this.httpClient.post<UserEditResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.replaceUser(editedUser);
              this.router.navigate(['/admin', 'users']);
            }
          }
        )
      );
  }

  public deleteTest(testId: string) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'DELETETEST');
    body.append('test_id', testId);

    return this.httpClient.post<TestDeleteResponse>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {


            //    If Successful remove Test from Loaded Tests

            if (response.success) {
              this.removeTest(testId);
            }
          }
        )
      );
  }

  public deleteProject(projectId: string) {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'DELETEPROJECT');
    body.append('project_id', projectId);

    return this.httpClient.post<ProjectDeleteResponse>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {


            //    If Successful remove Project from Loaded Projects

            if (response.success) {
              this.removeProject(projectId);
            }
          }
        )
      );
  }

  deleteUser(deleteUser: User) {
    const body = new FormData();

    body.append('func', 'DELETEUSER');

    body.append('token', this.userService.user.token);
    body.append('user_id', deleteUser.user_id);

    return this.httpClient.post<UserDeleteResponse>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.onUsersChange.next(
                this.users = this.onUsersChange.getValue().filter(user => {
                  return user.user_id !== deleteUser.user_id;
                })
              );
            }
          }
        )
      );
  }

  generateCSV(test: Test) {
    const body = new FormData();
    body.append('func', 'EXPORTCSV');
    body.append('token', this.userService.user.token);
    body.append('test_id', test.test_id);
    return this.httpClient.post<GenerateCSVResponse>(environment.ApiLocation, body);
    }
  resetUserLoginAttempts(user: User) {

    const editedUser = {...user};
    editedUser.locked = 0;


    const body = new FormData();

    body.append('func', 'RESETUSERLOGIN');

    body.append('token', this.userService.user.token);
    body.append('user_id', user.user_id);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.replaceUser(editedUser);
            }
          }
        )
      );
  }

  resetUserPassword(user: User) {

    const body = new FormData();

    body.append('func', 'RESETUSERPASSWORD');

    body.append('token', this.userService.user.token);
    body.append('user_id', user.user_id);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  private replaceUser(editedUser: User) {

    const index = this.users.findIndex(
      (user) =>  {
        return user.user_id === editedUser.user_id;
      });

    this.users[index] = Object.assign(this.users[index], editedUser);
    this.onUsersChange.next(this.users);
  }

  lockUserAt(user: User, whenToLock: number) {

    const editedUser = {...user};
    editedUser.lock_at = whenToLock;

    const body = new FormData();

    body.append('func', 'LOCKUSERAT');

    body.append('token', this.userService.user.token);
    body.append('user_id', user.user_id);
    body.append('lock_at', whenToLock.toString());

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.replaceUser(editedUser);
            }
          }
        )
      );
  }

  removeLockUserAt(user: User) {

    const editedUser = user;
    editedUser.lock_at = null;

    const body = new FormData();

    body.append('func', 'REMOVELOCKUSERAT');

    body.append('token', this.userService.user.token);
    body.append('user_id', user.user_id);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.replaceUser(editedUser);
            }
          }
        )
      );
  }

  fetchUserRightsFor(userId: string) {
    const body = new FormData();

    body.append('func', 'GETUSERRIGHTS');

    body.append('token', this.userService.user.token);
    body.append('user_id', userId);

    return this.httpClient.post<ServerGetUserRightsAndRolesResponseModel>(environment.ApiLocation, body);
  }

  fetchAvailableRoles() {
    const body = new FormData();

    body.append('func', 'GETROLES');
    body.append('token', this.userService.user.token);

    return this.httpClient.post<ServerGetRolesResponseModel>(environment.ApiLocation, body)
      .pipe(
        tap(
          (response) => {
            if (response.success) {
              this.availableRoles.next(response.data.roles);
            }
          }
        )
      );
  }


  updateUserRoles(UserId: string, newRoles: string[]) {
    const body = new FormData();

    body.append('func', 'UPDATEUSERROLES');
    body.append('token', this.userService.user.token);
    body.append('new_roles', newRoles.join(', '));
    body.append('user_id', UserId);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  newUser(params: { gender: any; lang: any; birthDate: any; email: any; username: any; activated: any }) {
    const body = new FormData();

    body.append('func', 'ADMINCREATEUSER');
    body.append('token', this.userService.user.token);

    for (const [key, param]  of Object.entries(params)) {
      body.append(key, param);
    }

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  saveTestChanges(testId: string, value: any, doCopy: boolean) {
    const body = new FormData();

    body.append('func', 'SAVETEST');
    body.append('testId', testId);
    body.append('token', this.userService.user.token);
    body.append('testDetails', JSON.stringify(value));
    body.append('doCopy', doCopy ? '1' : '0');

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  saveProjectChanges(projectId: string, value: any) {
    const body = new FormData();

    body.append('func', 'SAVEPROJECT');
    body.append('projectId', projectId);
    body.append('token', this.userService.user.token);
    body.append('projectDetails', JSON.stringify(value));

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }



  fetchImagesForTestId(testId: string) {
    const body = new FormData();

    body.append('func', 'GETIMAGESFORTEST');
    body.append('token', this.userService.user.token);
    body.append('testId', testId);

    return this.httpClient.post<GetImagesForTestResponseModel>(environment.ApiLocation, body);
  }

  fetchUserTestHistory(userId: string) {

    const body = new FormData();

    body.append('func', 'GETUSERTESTHISTORY');
    body.append('token', this.userService.user.token);
    body.append('user_id', userId);

    return this.httpClient.post<GetUserTestHistoryResponseModel>(environment.ApiLocation, body);
  }
  // drag proejct component down  //

  fetchUserProjectsFor(userId: string) {
    const body = new FormData();

    body.append('func', 'GETUSERPROJECTS');

    body.append('token', this.userService.user.token);
    body.append('user_id', userId);
    return this.httpClient.post<ServerGetUserProjectsResponseModel>(environment.ApiLocation, body);
  }

  updateProjectTests(ProjectId: string, newTests: TestOfProject[]) {
    const body = new FormData();

    body.append('func', 'UPDATEPROJECTTESTS');
    body.append('token', this.userService.user.token);
    body.append('new_tests', JSON.stringify(newTests));
    body.append('project_id', ProjectId);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

   fetchProjectTestsFor(projectId: string) {
    const body = new FormData();

    body.append('func', 'GETPROJECTTESTS');

    body.append('token', this.userService.user.token);
    body.append('project_id', projectId);
    return this.httpClient.post<ServerGetProjectTestsResponseModel>(environment.ApiLocation, body);
  }

  // User Project Admin benutzer
  fetchUserProject(userId: string) {
    const body = new FormData();

    body.append('func', 'GETUSERPROJECTADMIN');

    body.append('token', this.userService.user.token);
    body.append('user', userId);
    return this.httpClient.post<ServerGetUserProjectResponseModel>(environment.ApiLocation, body);
  }

  updateUserProjectAdmin(UserId: string, newProjects: string[]) {
    const body = new FormData();

    body.append('func', 'UPDATEUSERPROJECT');
    body.append('token', this.userService.user.token);
    body.append('new_projects', newProjects.join(', '));
    body.append('user_id', UserId);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }

  // get Animation
  fetchAnimation() {
    const body = new FormData();

    body.append('token', this.userService.user.token);
    body.append('func', 'GETANIMATION');


    return this.httpClient.post<AnimationResponseModel>(environment.ApiLocation, body)
      .pipe(tap(
        result => {
          // this.onTestsChange.next(result.data.tests);
        }
      ));
  }
  /*
  getUserProjectsForAdmin(userId: string) {
    const body = new FormData();

    body.append('func', 'GETUSERPROJECTS');
    body.append('token', this.userService.user.token);
    body.append('user_id', userId);

    return this.httpClient.post<getUserProjectsForAdmin>(environment.ApiLocation, body);
  }
  */





/*
  projects-drag(UserId: string, newRoles: string[]) {
    const body = new FormData();

    body.append('func', 'GETPROJECTTESTS');
    body.append('token', this.userService.user.token);
    body.append('new_tests', newRoles.join(', '));
    body.append('project_id', UserId);

    return this.httpClient.post<ServerResponseModel>(environment.ApiLocation, body);
  }
  */

}
