import { Injectable } from '@angular/core';
import {RegisterResponseModel} from "../../../shared/serverresponse.model";
import {environment} from "../../../../environments/environment";
import {tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import { LoginResponseModel, ServerDefaultSettingsResponseModel,
  ServerResponseModel,TestCreateResponseModel,
  ValidateResponseModel } from "../../../shared/serverresponse.model";
import {Image} from "../../../shared/image.model";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient,
    private router: Router) { }






newImg(imageZipFile, test_id) {
  const body = new FormData();

  body.append('func', 'CREATEIMAGES');
  body.append('zip_file', imageZipFile);
  body.append('test_id', test_id);
  body.append('zip_file', JSON.stringify(imageZipFile));

  console.log(body);
  console.log(imageZipFile);


  return this.httpClient.post<TestCreateResponseModel>(environment.ApiLocation, body) .pipe(
    tap(
      (response) => {
        if (response.success) {
          this.router.navigate(['/admin', 'tests']);
        }
      }
    )
  );
  }
}

