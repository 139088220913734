import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {TestService} from '../admin/tests/test.service';


@Injectable({ providedIn: 'root' })
export class TestResolverService implements Resolve<Test> {
  constructor(
    private testService: TestService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    if (this.testService.onTestsChange.getValue() == null) {
      return this.testService.fetchTestOverview();
    } else {
      return new Observable(
        subscriber => {
          subscriber.next(true);
          subscriber.complete();
        }
      );
    }

  }
}
