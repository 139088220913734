import {Component, Inject, OnInit} from '@angular/core';
import {LanguageService} from "../../language.service";
import {DialogComponent} from "../../dialog/dialog.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  title: string;
  content: string;
  okCancel: boolean;
  minDate: Date;
  maxDate: Date;
}

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss']
})
export class SelectTimeComponent implements OnInit {
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public languageService: LanguageService
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.minDate = new Date();
  }

}
