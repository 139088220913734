import { Component, OnInit, ViewChild  } from '@angular/core';
import {AdminService} from '../admin.service';
import {DialogService} from '../../shared/dialog.service';
import {LanguageService} from '../../shared/language.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Project} from './project';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit {

  constructor(
    private adminService: AdminService,
    private dialogService: DialogService,
    public languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
  }
  onProjectsChange = new BehaviorSubject<Project[]>(null);
  projects: Project[] = null;
  displayedColumns: string[] = [  'name', 'description', 'use_levels', 'actions'];
  dataSource = new MatTableDataSource(this.projects);

  expandedProject: string | null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  ngOnInit() {
    if (true) {
      this.adminService.fetchProjects().subscribe();
    }

    this.adminService.onProjectsChange.subscribe(
      (projects) => {
        if (projects !== null) {
          this.projects = projects;
          this.dataSource = new MatTableDataSource(this.projects);
         /* console.log(this.dataSource);*/
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    );
  }

  test() {
    this.router.navigate([
      '/admin',
     'projects',
      'details'
    ]);

  }

  toTestsOfProject(id) {
    this.router.navigate([
      '/admin',
      'projects',
      'drag',
       id
    ]);
  }
  onExpandedProjectChange(expandedProject: Project) {
    this.expandedProject = expandedProject.project_id;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  onDeleteProject(projectId: string) {
    this.adminService.deleteProject(projectId).subscribe(
      response => {

        if (response.success) {
          this.dialogService.openSnackBar(response. data);
        } else {
          this.dialogService.openDialog('error', response.errors);
        }
      }
    );
  }

  // onDeleteProject(project: Project) {
  //   this.dialogService.openDialog('delete_project', ['delete_project_are_you_sure'], false, true).afterClosed().subscribe(
  //     result => {
  //       if (result) {
  //         this.adminService.deleteProject(project).subscribe(
  //           (callbackResult) => {
  //             console.log(callbackResult);
  //             if (callbackResult.success) {
  //               this.dialogService.openSnackBar(callbackResult.data[0]);
  //             } else {
  //               this.dialogService.openDialog('error', callbackResult.errors);
  //             }
  //           }
  //         );
  //       }
  //     });
  // }


}
