import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserModel} from '../user.model';
import * as moment from 'moment';
import {UserService} from '../user.service';
import {LanguageService} from '../../shared/language.service';
import {DialogService} from '../../shared/dialog.service';
import {Language} from '../../shared/language.model';
import {AppService} from '../../app.service';
import {AdminService} from '../../admin/admin.service';
import {UserSettings} from './user-settings';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public editAccountFormGroup: FormGroup;
  public languages: Language[];
  public genders: string[];
  public  settings: UserSettings | null = null;
  private formReady = false;

  constructor(
    private userService: UserService,
    public languageService: LanguageService,
    private dialogService: DialogService,
    private adminService: AdminService,
    public appService: AppService
  ) { }

  ngOnInit() {
    this.languages = this.languageService.languages;
    this.genders = this.appService.genders;

    this.userService.onUserChange.subscribe(
        newUser => {
          if (this.formReady) {
            this.editAccountFormGroup.controls.userSettings.patchValue(newUser.userSettings);
          }
        }
    );

    if (this.userService.user.userSettings) {
      this.settings = this.userService.user.userSettings;
      // console.log({user: this.userService.user})
      this.initForm();
    } else {
      this.userService.getDefaultTestSettings().subscribe(
        result => {
          this.settings = result.data;
          this.initForm();
        }
      );
    }
  }

  initForm() {
    // console.log({settings: this.settings})
    this.editAccountFormGroup = new FormGroup({
      username: new FormControl({value: this.userService.user.username, disabled: true}, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]),
      email: new FormControl({value: this.userService.user.email, disabled: false}, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.email
      ]),
      gender: new FormControl(this.userService.user.gender, [
        Validators.required
      ]),
      birthdate: new FormControl({value: new Date(this.userService.user.birthdate), disabled: false}, [
      ]),
      password: new FormControl('', [
      ]),
      password_repeat: new FormControl('', [
      ]),

      userSettings: new FormGroup({
        allowed_attempts: new FormControl(this.settings.allowed_attempts, [
          Validators.required
        ]),
        show_neutral_images: new FormControl(this.settings.show_neutral_images === 1),
        neutral_image_time: new FormControl(this.settings.neutral_image_time),
        show_emotional_images: new FormControl({value: true, disabled: true}),
        emotional_image_time: new FormControl(this.settings.emotional_image_time),
        between_image_time: new FormControl(this.settings.between_image_time),
        max_selection_time: new FormControl(this.settings.max_selection_time),
        play_sound_on_start: new FormControl(this.settings.max_selection_time === 1),
      })
  });

    this.formReady = true;
  }

  onSubmitMail() {
    if (this.editAccountFormGroup.controls.email.valid) {
      this.userService.updateUserEmail(this.editAccountFormGroup.value.email.trim()).subscribe(
        result => {
          if (result.success) {
            this.dialogService.openSnackBar(result.data[0]);
          }  else {
            this.dialogService.openDialog('error', result.errors);
          }
        }
      );
    }
  }

  onSubmitPassword() {
    if (this.editAccountFormGroup.controls.password.valid) {
      this.userService.updatePassword(this.editAccountFormGroup.value.password.trim()).subscribe(
        result => {
          if (result.success) {
            this.dialogService.openSnackBar(result.data[0]);
          } else {
            this.dialogService.openDialog('error', result.errors);
          }
        }
      );
    }
  }

  onSubmitDetails() {
      this.userService.updateUserDetails(
        JSON.stringify(moment(this.editAccountFormGroup.controls.birthdate.value)),
        this.editAccountFormGroup.value.gender.trim()
      ).subscribe(
        result => {

          if (result.success) {
            this.dialogService.openSnackBar(result.data[0]);
          } else {
            this.dialogService.openDialog('error', result.errors);
          }

        }
      );

    }

  onSubmitSettings() {
    // console.log(this.editAccountFormGroup);
    if (this.editAccountFormGroup.controls.userSettings.valid) {
      this.userService.updateUserSettings({
        allowed_attempts: this.editAccountFormGroup.value.userSettings.allowed_attempts,
        show_neutral_images: this.editAccountFormGroup.value.userSettings.show_neutral_images,
        neutral_image_time:  this.editAccountFormGroup.value.userSettings.neutral_image_time,
        show_emotional_images:  this.editAccountFormGroup.value.userSettings.show_emotional_images,
        emotional_image_time:  this.editAccountFormGroup.value.userSettings.emotional_image_time,
        between_image_time: this.editAccountFormGroup.value.userSettings.between_image_time,
        max_selection_time:  this.editAccountFormGroup.value.userSettings.max_selection_time,
        play_sound_on_start:  this.editAccountFormGroup.value.userSettings.play_sound_on_start,
      }).subscribe(
          result => {
            if (result.success) {
              this.dialogService.openSnackBar(result.data[0]);
            } else {
              this.dialogService.openDialog('error', result.errors);
            }
          }
      );
    }
  }

  onSubmitResetSettings() {
    this.dialogService.openDialog('confirm', ['reset_test_settings_to_default'], false, true).afterClosed().subscribe(
        result => {
          if (result) {
            this.userService.resetUserTestSettings().subscribe(
              (response) => {
                if (response.success) {
                  this.dialogService.openSnackBar(response.data[0]);
                } else {
                  this.dialogService.openDialog('error', response.errors);
                }
              }
            );
          }
        }
    );
  }
}
