import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogComponent} from '../../dialog/dialog.component';
import {LanguageService} from '../../language.service';

export interface DialogData {
  title: string;
  selectedEmotion: string;
  selectedIntensity: number;
  emotion: string;
  intensity: number;
}

@Component({
  selector: 'app-test-testphase-results',
  templateUrl: './test-testphase-results.component.html',
  styleUrls: ['./test-testphase-results.component.scss']
})
export class TestTestphaseResultsComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public languageService: LanguageService
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  getDifference(num1: number, num2: number) {
    if (this.data.emotion !== this.data.selectedEmotion) {
      return this.languageService.lang('wrong_emotion');
    } else {
      return Math.abs(num1 - num2);
    }
  }
}
