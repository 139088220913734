import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {MatDrawer} from '@angular/material/sidenav';
import { genders } from '../assets/js/genders';
import { pages  } from '../assets/js/pages';
import { adminPages  } from '../assets/js/adminPages';
import { emotions  } from '../assets/js/emotions';
import {Page} from './shared/admin.model';
import {UserService} from './user/user.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {environment} from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  public defaultLanguage: 'de';

  public header = new BehaviorSubject<{height: number, width: number}>(null);

  public isBiggerThanLg = false;
  public isBiggerThanMd = false;
  public isBiggerThanSm = false;
  public isBiggerThanXs = false;

  public isSmallerThanXl = false;
  public isSmallerThanLg = false;
  public isSmallerThanMd = false;
  public isSmallerThanSm = false;

  public isXl = false;
  public isLg = false;
  public isMd = false;
  public isSm = false;
  public isXs = false;
  private breakpointSub: Subscription;
  public onBreakpointChanges: BehaviorSubject<null|string> = new BehaviorSubject(null);

  public genders: string[] = genders;
  private pages: Page[] = pages;
  private adminPages: Page[] = adminPages;

  public drawerStateChange = new BehaviorSubject(false);
  private drawer: MatDrawer;
  emotions: {
    name: string,
    class: string,
    icon?: string
  }[] = emotions;

  constructor(
    private userService: UserService,
    private breakpointObserver: BreakpointObserver
) {}

  //    Navigation Control Functions

      //    Set Element Reference
  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;

    this.drawerStateChange.subscribe(
      result => {
        if (result) {
          this.drawer
            .open()
            .then(() => true);
        } else {
          this.drawer
            .close()
            .then(() => false);
        }
      }
    );
  }

  public getDrawerState() {
    return this.drawer.opened;
  }

  public toggleSideNav() {
    this.drawer.toggle().then();
  }

  public closeSideNav() {
    this.drawer.close().then();
  }

  public openSideNav() {
    this.drawer.open().then();
  }

  getPages() {
    if (!this.userService.validLogin()) {
      return this.pages.filter( page => {
        return page.permission === '' || page.permission === null;
      });
    } else {
      return this.pages.filter( page => {
        return (
          this.userService.hasRightTo(page.permission) ||
          page.permission === null ||
          page.permission === ''
          ) &&
          page.onlyShowLoggedOut === 0;
      });
    }
  }

  getAdminPages() {
    if (!this.userService.validLogin()) {
      return null;
    } else {
      return this.adminPages.filter(page => {
        return this.userService.hasRightTo(page.permission);
      });
    }
  }

  ngOnInitialLoad() {
    let setupStatusMessage = 'setup_complete';
    let setupStatusSuccessful = true;
    try {
    this.breakpointSub = this.breakpointObserver.observe([
      Breakpoints.XLarge,
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      Breakpoints.XSmall,
    ]).subscribe(
      breakpoint => {
        // console.log(breakpoint);
        if (breakpoint.breakpoints['(max-width: 599.99px)']) {
          this.isXl = false;
          this.isLg = false;
          this.isMd = false;
          this.isSm = false;
          this.isXs = true;

          this.isBiggerThanLg = false;
          this.isBiggerThanMd = false;
          this.isBiggerThanSm = false;
          this.isBiggerThanXs = false;

          this.isSmallerThanXl = true;
          this.isSmallerThanLg = true;
          this.isSmallerThanMd = true;
          this.isSmallerThanSm = true;
          this.onBreakpointChanges.next('xs');

        } else if (breakpoint.breakpoints['(min-width: 600px) and (max-width: 959.99px)']) {

          this.isXl = false;
          this.isLg = false;
          this.isMd = false;
          this.isSm = true;
          this.isXs = false;

          this.isBiggerThanLg = false;
          this.isBiggerThanMd = false;
          this.isBiggerThanSm = false;
          this.isBiggerThanXs = true;

          this.isSmallerThanXl = true;
          this.isSmallerThanLg = true;
          this.isSmallerThanMd = true;
          this.isSmallerThanSm = false;
          this.onBreakpointChanges.next('sm');

        } else if (breakpoint.breakpoints['(min-width: 960px) and (max-width: 1279.99px)']) {

          this.isXl = false;
          this.isLg = false;
          this.isMd = true;
          this.isSm = false;
          this.isXs = false;

          this.isBiggerThanLg = false;
          this.isBiggerThanMd = false;
          this.isBiggerThanSm = true;
          this.isBiggerThanXs = true;

          this.isSmallerThanXl = true;
          this.isSmallerThanLg = true;
          this.isSmallerThanMd = false;
          this.isSmallerThanSm = false;
          this.onBreakpointChanges.next('md');

        } else if (breakpoint.breakpoints['(min-width: 1280px) and (max-width: 1919.99px)']) {

          this.isXl = false;
          this.isLg = true;
          this.isMd = false;
          this.isSm = false;
          this.isXs = false;

          this.isBiggerThanLg = false;
          this.isBiggerThanMd = true;
          this.isBiggerThanSm = true;
          this.isBiggerThanXs = true;

          this.isSmallerThanXl = true;
          this.isSmallerThanLg = false;
          this.isSmallerThanMd = false;
          this.isSmallerThanSm = false;
          this.onBreakpointChanges.next('lg');

        } else if (breakpoint.breakpoints['(min-width: 1920px)']) {

          this.isXl = true;
          this.isLg = false;
          this.isMd = false;
          this.isSm = false;
          this.isXs = false;

          this.isBiggerThanLg = true;
          this.isBiggerThanMd = true;
          this.isBiggerThanSm = true;
          this.isBiggerThanXs = true;

          this.isSmallerThanXl = false;
          this.isSmallerThanLg = false;
          this.isSmallerThanMd = false;
          this.isSmallerThanSm = false;
          this.onBreakpointChanges.next('xl');
        }
      }
    );
    } catch (e) {
      console.log(e);
      setupStatusMessage = 'setup_failed';
      setupStatusSuccessful = false;
    }

    // tslint:disable:no-console
    console.info(
      `%c Powered by %c code<n>ground AG %c v${environment.version} `,
      'background:#0088AB ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff; font-size: 12px;',
      'font-weight: bold; background:#85AD28; padding: 1px; color: #fff; font-size: 12px;',
      'background:#C3002F ; padding: 1px; border-radius: 0px 3px 3px 0;  color: #fff; font-size: 12px;'
    );
    console.info(`%c[APP] running in %c ${environment.deploymentType.toUpperCase()} %c mode >> %c ${setupStatusMessage.toUpperCase()} `,
    'color: white; font-size: 12px; padding: 1px;',
      `font-weight: bold; color: white; background-color:${environment.deploymentType === 'production' ? '#85AD28' : environment.deploymentType !== 'staging' ? '#C3002F' :  '#0088AB' }; font-size: 12px; padding: 1px; border-radius: 4px;`,
      'color: white; font-size: 12px; padding: 1px;',
      `font-weight: bold; color: white; font-size: 12px; padding: 1px; background:${setupStatusSuccessful ? '#85AD28' : '#C3002F'}; border-radius: 3px`,
    );
  }
}
