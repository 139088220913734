// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  deploymentType: 'development',
  version: '2.0',
  ApiLocation: 'https://feel-test.uni-ulm.de/backend/',
  donwloadCsvPath : 'https://feel-test.uni-ulm.de/backend/upload/',
  production: false,
  debug: true
};
// ApiLocation: 'https://feel-test.uni-ulm.de/backend/',
//    donwloadCsvPath : 'https://feel-test.uni-ulm.de/backend/upload/',
//ApiLocation: 'http://134.60.153.143/backend/',
//   donwloadCsvPath : 'http://134.60.153.143/backend/upload/',
// Local (whole repo)
//ApiLocation: 'https://feel-test.uni-ulm.de/backend/',
//   donwloadCsvPath : 'https://feel-test.uni-ulm.de/backend/upload/',
//ApiLocation: 'http://feel-test.uni-ulm.de/backend/',
//   donwloadCsvPath : 'http://feel-test.uni-ulm.de/backend/upload/',
// ApiLocation: 'http://134.60.153.143/uniulm-feeltest/backend/',
//   donwloadCsvPath : 'http://134.60.153.143/uniulm-feeltest/backend/upload/',
//ApiLocation: 'http://localhost/uniulm-feeltest/trunk/backend/',
//   donwloadCsvPath : 'http://localhost/uniulm-feeltest/trunk/backend/upload/',
// Local (only trunk)
// ApiLocation: 'http://localhost/uniulm-feeltest/backend/',
//   donwloadCsvPath : 'http://localhost/uniulm-feeltest/backend/upload/',
//Test
// ApiLocation: 'http://test.feeltest.codenground.net/backend/',
//   donwloadCsvPath : 'http://test.feeltest.codenground.net/backend/upload/',
// Live
// ApiLocation: 'http://feeltest.codenground.net/backend/',
//   donwloadCsvPath : 'http://feeltest.codenground.net/backend/upload/',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
